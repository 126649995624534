import React from 'react'

const Friday_Id_Orientia_tsutsugamushi = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row mt-5'>
                    <div className='col-md-7 col-sm-12 '>
                        <p style={{ textAlign: 'justify' }}>Orientia tsutsugamushi, causative bacteria for Scrub typhus, the most common re-emerging rickettsial infection in SE Asia including India.  </p>
                        <p style={{ textAlign: 'justify' }}>An infection never to be forgotten especially during the monsoon season when a patient presents with febrile illness. </p>
                        <p style={{ textAlign: 'justify' }}>It’s often misdiagnosed as dengue due to similar clinical presentation and associated thrombocytopenia on a regular evaluation. </p>
                        <p style={{ textAlign: 'justify' }}>Always search for ESCHAR, dark brown or black crusty skin lesion, formed by the bite of the mite that inoculates the above mentioned bacteria. </p>
                        <p style={{ textAlign: 'justify' }}>Highlighting few differentiating features from dengue that can help on a busy OP day for a general practitioner. </p>
          
                    </div>
                    <div className='col-md-5 col-sm-12'>
                        <img src='web.whatsapp1.png' style={{ width: '100%' }}></img>
                    </div>
                    <p style={{ textAlign: 'justify' }}>1) Unlike Dengue, hypotension isn’t common, though they will have a similar toxic look</p>
                        <p style={{ textAlign: 'justify' }}>2) More respiratory distress at presentation </p>
                        <p style={{ textAlign: 'justify' }}>3) Leucocytosis and neutrophilia is common unlike dengue in presence of thrombocytopenia. </p>
                        <p style={{ textAlign: 'justify' }}>4) Actually, it’s spurious thrombocytopenia due to platelet clumps and a peripheral smear alerts the possibility. </p>
                        <p style={{ textAlign: 'justify' }}>5) Searching for ESCHAR in warm and damp areas like perineum, inguinal region, axilla and underneath the breasts is important. </p>
                        <p style={{ textAlign: 'justify' }}>RDT’s can be misleading and a proper clinical evaluation combined with ELISA/IFA is important to diagnose scrub typhus. </p>
                        <p style={{ textAlign: 'justify' }}>Luckily there isn’t much resistance as of now to tetracyclines and Azithromycin, but need to strongly monitor and manage end organ damage, if any.</p>
                     

                </div>
            </div>
        </>
    )
}

export default Friday_Id_Orientia_tsutsugamushi