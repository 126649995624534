import React from 'react'

const Friday_Id_Message_Lucidly_Integrated_Vitalcare_in_Emergency_LIVE = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row mt-5'>
                    <div className='col-md-7 col-sm-12 '>
                        <p style={{ textAlign: 'justify' }}>LIVE - Lucidly Integrated Vitalcare in Emergency is one of the first trusted and iconic smart ICU program being adapted at SHRI hospital since one year. </p>
                        <p style={{ textAlign: 'justify' }}>It isn’t just a tele - ICU. It is a novel technology, where automation, integration and  collaboration come together dedicatedly to keep patients continuously monitored in very critical scenario. </p>
                        <p style={{ textAlign: 'justify' }}>Once the patient enters the ICU and as soon as credentials are entered, their health condition is immediately captured by this novel integrated technology and the data of their clinical status is continuously streamed which can be accessed anywhere, within the ICU or even outside the ICU. </p>
                        <p style={{ textAlign: 'justify' }}>The Intensivist/super specialists in turn evaluate the data that is being streamed and make suggestions live to the medical team attending the patient bedside. </p>
                       

                    </div>
                    <div className='col-md-5 col-sm-12'>
                        <img src='web.whatsapp1.png' style={{ width: '100%' }}></img>
                    </div>
                    <p style={{ textAlign: 'justify' }}>Also, there is an inbuilt machine learning and AI model called deterioration index, which helps in identifying patients who are worsening earlier than they are actually recognised by clinical evaluation/regular rounds. As majority of the critical patients are being shifted from rural health centres to higher center for further management during crucial time, initial resuscitation and managing the patients further can be possible with smart ICU technology from remote location. </p>
                        <p style={{ textAlign: 'justify' }}>Now, for the first time in the state, team SHRI made a valiant and successful attempt to create a similar environment in small rural setting, Govt. area hospital, Chilakaluripeta. </p>
                        <p style={{ textAlign: 'justify' }}>We are happy to share that this pilot project was launched by our honorable health minister, Vidadala Rajani garu recently.</p>
                
                </div>
            </div>
        </>
    )
}

export default Friday_Id_Message_Lucidly_Integrated_Vitalcare_in_Emergency_LIVE