import React from 'react'

const Why_colds_and_flu_viruses_are_more_common_in_winter = () => {
    return (
        <>
        <div id='Topmargin'></div>
        <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
          <div className="col text-center strip1" style={{ color: 'white' }}>
            <div className="row mt-4" >
              <h1 style={{ textTransform: 'uppercase' }}>Why colds and flu viruses are more common in winter And Taking Care</h1>
            </div>
            <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
              <li ><a href="/home">Home</a></li>
            </ul>
          </div>
        </div>
    
    
       
        <div className="container mt-2 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
          <div className="row">
            <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                  <span style={{ color: "#ff7800" }}>Why colds and flu viruses in winter</span>
                </h1>
              <p style={{ textAlign: 'justify' }}>
                {/* Graduation from Guntur Medical College and Post Graduation from Manipal Academy of Higher Education. 
                    Awarded PG certificate in Infectious diseases from London School of Hygiene and Tropical Medicine, UK and is the 1st ID specialist of our state. */}
As winter approaches, so does the annual resurgence of colds and flu. Understanding why these illnesses become more prevalent during colder months can empower us to take proactive steps in safeguarding our health. In this blog post, we'll delve into the medical reasons behind the increased incidence of colds and flu in winter, and provide practical tips on how to stay healthy during this season.              </p>
    
              <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                <span style={{ color: "rgb(245, 41, 41)" }}>Why Colds and Flu Thrive in Winter</span>
              </h4 >
              <h5 style={{  fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                  <span style={{ color: "#ff7800" }}>Indoor Crowding and Close Contact</span>
                </h5>
    
                    <p style={{ textAlign: 'justify' }}>In winter, people tend to spend more time indoors to escape the cold. This leads to a higher density of individuals in enclosed spaces, creating an ideal environment for the rapid transmission of colds and flu.</p>
              {/* <p style={{ textAlign: 'justify' }}>
              Started Center for Infectious Diseases in Guntur with state of the art Micobiology lab supported with automated culture systems, PCR and CB-NAAT technologies.
              </p>
              <p style={{ textAlign: 'justify' }}>
              He is also pioneer in initiating first of its kind integrated health care with remote patient monitoring .Health ATM is his brain child that helps to monitor the patients 24/7 even from home or any other remote location.
              </p> */}
            </div>
            <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg mt-5">
              <img className=" img-thumbnail shadow-lg p-2 mb-5 bg-body rounded" style={{ height: '250px', borderRadius: '10px', width: '300px', boxShadow: 'grey' }} id="ServicesImG" src='woman-with-a-cold.jpg' alt=''></img>
            </div>
          </div>
        
    
             
              <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                <span style={{ color: "#ff7800" }}>Low Humidity Levels and Vulnerable Mucous Membranes</span>
              </h5 >
            <p style={{ textAlign: 'justify' }}>Cold air is naturally drier than warm air, leading to lower humidity levels indoors. Dry air can dry out the mucous membranes in our respiratory system, making it easier for viruses to infiltrate our bodies.</p>
              <h5 style={{  fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                  <span style={{ color: "#ff7800" }}>Weakened Immune Response</span>
                </h5>
              <p style={{ textAlign: 'justify' }}>Exposure to cold temperatures can have a suppressive effect on the immune system. Studies have shown that colder temperatures may hinder the function of immune cells, making it more difficult for the body to defend against invading viruses</p>
                <h5 style={{  fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                  <span style={{ color: "#ff7800" }}>Viral Stability in Cold Conditions</span>
                </h5>
              <p style={{ textAlign: 'justify' }}>Some viruses, including the influenza virus, are more stable and can survive longer in colder, drier conditions. This extended viability increases the chances of transmission from contaminated surfaces to humans.</p>
              <h5 style={{  fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                  <span style={{ color: "#ff7800" }}>Reduced Sunlight and Vitamin D Deficiency</span>
                </h5>
               <p style={{ textAlign: 'justify' }}>Reduced exposure to sunlight in winter can lead to lower levels of vitamin D in the body. This vitamin plays a crucial role in immune function, and its deficiency may leave individuals more susceptible to infections.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                <span style={{ color: "rgb(245, 41, 41)" }}>Taking Care in Winter for Flu Prevention</span>
              </h4 >
              <h5 style={{  fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                  <span style={{ color: "#ff7800" }}>Practice Good Hygiene</span>
                </h5>
              <p style={{ textAlign: 'justify' }}>Regular handwashing, avoiding touching your face, and covering your mouth and nose with a tissue or elbow when coughing or sneezing can help prevent the spread of viruses.</p>
                <h5 style={{  fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                  <span style={{ color: "#ff7800" }}>Maintain Indoor Humidity Levels</span>
                </h5>
              <p style={{ textAlign: 'justify' }}>Use a humidifier to add moisture to indoor air, which can help keep mucous membranes in the respiratory system moist and less susceptible to viral invasion.</p>
                <h5 style={{  fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                  <span style={{ color: "#ff7800" }}>Boost Your Immune System</span>
                </h5>

              <p style={{ textAlign: 'justify' }}>Ensure you're getting enough sleep, staying physically active, and eating a balanced diet rich in vitamins and minerals. Consider consulting a healthcare provider about supplements, particularly vitamin D, if you're deficient.</p>

                <h5 style={{  fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                  <span style={{ color: "#ff7800" }}>Prioritize Ventilation</span>
                </h5>
              <p style={{ textAlign: 'justify' }}>Even in colder weather, ensure that indoor spaces are adequately ventilated to reduce the concentration of viral particles.</p>

                <h5 style={{  fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                  <span style={{ color: "#ff7800" }}>Get Vaccinated</span>
                </h5>

              <p style={{ textAlign: 'justify' }}>Getting a flu vaccine is one of the most effective ways to prevent the flu. Consult with your healthcare provider about the appropriate vaccination for your age group and health status.</p>
                <h5 style={{  fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                  <span style={{ color: "#ff7800" }}>Stay Informed and Seek Medical Advice</span>
                </h5>
              <p style={{ textAlign: 'justify' }}>Keep yourself updated on local health advisories and seek prompt medical attention if you experience flu-like symptoms.</p>
                <h5 style={{  fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                  <span style={{ color: "#ff7800" }}></span>
                </h5>
               
        </div>
    
      </>
      )
}

export default Why_colds_and_flu_viruses_are_more_common_in_winter