import React from 'react'

const Friday_Id_Message_In_view_of_rising_multi_drug_resistance_MDR = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row mt-5'>
                    <div className='col-md-7 col-sm-12'>
                        <p style={{ textAlign: 'justify' }}>In view of rising multi drug resistance (MDR) proper blood and urine cultures are mandatory if febrile illness persists over few days. </p>
                        <p style={{ textAlign: 'justify' }}>A 25 yr old female with no significant past history/comorbidities was admitted with febrile illness for 10 days duration. Further baseline evaluation showed moderate thrombocytopenia with relatively normal counts. Other baseline work up showed mild hepatitis with normal renal parameters. USG abdomen revealed mild cystitis. Cardio respiratory- NAD. </p>
                        <p style={{ textAlign: 'justify' }}>Tropical infection work up turned out to be negative. While awaiting culture reports, started on carbapenem as patient was intially treated with cephalosporins and quinolones. Her fever persisted with progressive thrombocytopenia and hypotension. While we were planning to evaluate further, urine culture revealed MDR E.coli. Patient responded to Colistin immediately in less than 24 hrs with improved clinical condition and platelet counts suggesting urosepsis. Without a proper culture, it wouldn’t have been possible to initiate antibiotics like Colistin especially in younger population with no comorbidities. Inadvertent and improper use of antibiotics in the society might be leading to scenarios as above.</p>
                     

                    </div>
                    <div className='col-md-5 col-sm-12'>
                        <img src='web.whatsapp1.png' style={{ width: '100%' }}></img>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Friday_Id_Message_In_view_of_rising_multi_drug_resistance_MDR