import React from 'react'

const Friday_Id_Message_Patients_with_sepsis_induced_shock = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row mt-5'>
                    <div className='col-md-7 col-sm-12'>
                        <p style={{ textAlign: 'justify' }}>Patients with sepsis induced shock, mean arterial pressure(MAP) of 65mm Hg has to be maintained. </p>
                        <p style={{ textAlign: 'justify' }}>After initial fluid resuscitation, if patient is still in hypotension greater than 65 MAP, use noradrenaline as first line vasopressor. </p>
                        <p style={{ textAlign: 'justify' }}>If MAP is inadequate despite moderate dose of noradrenaline, add vasopressin as second line agent. </p>
                        <p style={{ textAlign: 'justify' }}>If cardiac dysfunction with persistent hypoperfusion, despite adequate volume status, add dobutamine to noradrenaline as second line agent. </p>
                        <p style={{ textAlign: 'justify' }}>Dopamine is found to be inferior to noradrenaline due to higher risk of arrhythmias. </p>
                        <p style={{ textAlign: 'justify' }}>Consider invasive monitoring of arterial blood pressure as soon as possible and when resources are available if patient is on inotropes.</p>
                       
                    </div>
                    <div className='col-md-5 col-sm-12 '>
                        <img src='web.whatsapp1.png' style={{ width: '100%' }}></img>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Friday_Id_Message_Patients_with_sepsis_induced_shock