import React from 'react'

const Friday_Id_Message__MIC_Minimum_Inhibitory_Concentration = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row mt-5'>
                    <div className='col-md-7 col-sm-12 '>
                        <p style={{ textAlign: 'justify' }}>MIC, Minimum Inhibitory Concentration has to be understood clearly while interpreting results of automated culture systems like Vitek-2, which are based on CLSI guidelines. </p>
                        <p style={{ textAlign: 'justify' }}>MIC is the lowest concentration of the drug that inhibits growth of given strain of bacteria. </p>
                        <p style={{ textAlign: 'justify' }}>Selection of the drug shouldn’t be based on MIC value only because sensitive break points for each drug varies. </p>
                        <p style={{ textAlign: 'justify' }}>So, Therapeutic index(TI) has to be used, which is the numerical obtained by dividing sensitive break point/MIC. </p>
                        <p style={{ textAlign: 'justify' }}>Higher the TI, better the sensitivity. </p>
                        <p style={{ textAlign: 'justify' }}>For eg; </p>
                        <p style={{ textAlign: 'justify' }}>drug 1 has MIC 1, sensitive break point 8 </p>
                        <p style={{ textAlign: 'justify' }}>and </p>
                        <p style={{ textAlign: 'justify' }}>drug 2 has MIC 0.5, sensitive break point as 2..</p>
                    </div>
                    <div className='col-md-5 col-sm-12' style={{ justifyItems: 'center' }}>
                        <img src='web.whatsapp1.png' style={{ width: '80%' }}></img>
                    </div>
                    <p style={{ textAlign: 'justify' }}>Which is the best drug?</p>
                    <p style={{ textAlign: 'justify' }}>Let’s calculate TI for both.,</p>
                    <p style={{ textAlign: 'justify' }}>For drug 1, TI is 8</p>
                    <p style={{ textAlign: 'justify' }}>For drug 2, TI is 4.,</p>
                    <p style={{ textAlign: 'justify' }}>So, drug 1 is better option.</p>
                    <p style={{ textAlign: 'justify' }}>If you just see the MIC alone, drug 2 appears to be better as it has lower value.</p>
                    <p style={{ textAlign: 'justify' }}>So, we need to keep calculate Therapeutic index(TI) before confirming the best option. </p>
                    <p style={{ textAlign: 'justify' }}>Higher the TI, better the sensitivity.</p>
                </div>
            </div>
        </>
    )
}

export default Friday_Id_Message__MIC_Minimum_Inhibitory_Concentration