import React from 'react'
import { Card, Carousel } from 'react-bootstrap';


const Testmonialss = () => {


  return (
    <>
      <Carousel data-bs-theme="light" indicators={false} >
        <Carousel.Item>
          <div className='container card-wrapper'>
            <Card className="carded" >
              <Card.Img variant="top" src="avatar.png" alt='' style={{ backgroundColor: '#ff7800', borderRadius: '50%', width: '35%', height: '30%', marginLeft: 'auto', marginRight: 'auto', display: 'block', marginTop: '10px' }} />

              <Card.Body>
                <Card.Title className='text-center'><b>Venkata Kavya</b></Card.Title>
                <Card.Text className='mt-2'>
                  Best physician for infectious diseases and diabetes care ..very well received and comfortable.... Regular follow ups and patient care is the best about the help hospital and Dr Kalyan chakravarthy sir                </Card.Text>

              </Card.Body>
            </Card>
            <Card className="carded" >
              <Card.Img variant="top" src="avatar.png" alt='' style={{ backgroundColor: '#ff7800', borderRadius: '50%', width: '35%', height: '40%', marginLeft: 'auto', marginRight: 'auto', display: 'block', marginTop: '10px' }} />

              <Card.Body>
                <Card.Title className='text-center'><b>Shaik Khurshidkhan</b></Card.Title>
                <Card.Text className='mt-2'>
                  </Card.Text>
                  Dr. Kalayan garu is the best infectious diseases specialist. Me and my family always come to Dr. Kalyna garu for any health issues and he suggests the best of best treatments. Best doctor for fever, diabetes, any other infectious diseases.
              </Card.Body>
            </Card>
            <Card className="carded" >
              <Card.Img variant="top" src="avatar.png" alt='' style={{ backgroundColor: '#ff7800', borderRadius: '50%', width: '35%', height: '30%', marginLeft: 'auto', marginRight: 'auto', display: 'block', marginTop: '10px' }} />

              <Card.Body>
                <Card.Title className='text-center'><b>Vinod Kumar</b></Card.Title>
                <Card.Text className='mt-2'>
                Best physician in guntur. Treats with patience and gives less medicines or as per requirement. Best doctor for fever, diabetes and infections diseases.                </Card.Text>

              </Card.Body>
            </Card>
          </div>

        </Carousel.Item>
        <Carousel.Item>
          <div className='container card-wrapper'>
            <Card className="carded" >
              <Card.Img variant="top" src="avatar.png" alt='' style={{ backgroundColor: '#ff7800', borderRadius: '50%', width: '35%', height: '45%', marginLeft: 'auto', marginRight: 'auto', display: 'block', marginTop: '10px' }} />

              <Card.Body>
                <Card.Title className='text-center'><b>Govindh</b></Card.Title>
                <Card.Text className='mt-2'>
                Dr. Kalayan chakravarthy garu is the best infectious diseases specialist. Me and my family always come to Dr. Kalyna garu for any health issues and he suggests the best of best treatments. Best doctor for fever, diabetes, any other infectious diseases.
                </Card.Text>

              </Card.Body>
            </Card>
            <Card className="carded" >
              <Card.Img variant="top" src="avatar.png" alt='' style={{ backgroundColor: '#ff7800', borderRadius: '50%', width: '35%', height: '25%', marginLeft: 'auto', marginRight: 'auto', display: 'block', marginTop: '10px' }} />

              <Card.Body>
                <Card.Title className='text-center'><b>kamala laya</b></Card.Title>
                <Card.Text className='mt-2'>
                Dr kalyanchakravarthi is the Best doctor for fever and diabetes care. great treatment, and valuable suggestions. Thank you doctor for such great work.                           </Card.Text>

              </Card.Body>
            </Card>
            <Card className="carded" >
              <Card.Img variant="top" src="avatar.png" alt='' style={{ backgroundColor: '#ff7800', borderRadius: '50%', width: '35%', height: '25%', marginLeft: 'auto', marginRight: 'auto', display: 'block', marginTop: '10px' }} />

              <Card.Body>
                <Card.Title className='text-center'><b>Nirmala</b></Card.Title>
                <Card.Text className='mt-2'>
                extremely professional, and very patient when it comes to listening to my concerns. He discussed the diagnosis and possible treatment options & addressed every single doubt which made me very comfortable.                </Card.Text>

              </Card.Body>
            </Card>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className='container card-wrapper'>
            <Card className="carded" >
              <Card.Img variant="top" src="avatar.png" alt='' style={{ backgroundColor: '#ff7800', borderRadius: '50%', width: '35%', height: '30%', marginLeft: 'auto', marginRight: 'auto', display: 'block', marginTop: '10px' }} />

              <Card.Body>
                <Card.Title className='text-center'><b>Mounika</b></Card.Title>
                <Card.Text className='mt-2'>
                Best physician in guntur. Treats with patience and gives less medicines or as per requirement. Best doctor for fever, diabetes and infections diseases.              
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="carded" >
              <Card.Img variant="top" src="avatar.png" alt='' style={{ backgroundColor: '#ff7800', borderRadius: '50%', width: '35%', height: '25%', marginLeft: 'auto', marginRight: 'auto', display: 'block', marginTop: '10px' }} />

              <Card.Body>
                <Card.Title className='text-center'><b>Pradeep</b></Card.Title>
                <Card.Text className='mt-2'>
                  is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                </Card.Text>

              </Card.Body>
            </Card>
            <Card className="carded" >
              <Card.Img variant="top" src="avatar.png" alt='' style={{ backgroundColor: '#ff7800', borderRadius: '50%', width: '35%', height: '45%', marginLeft: 'auto', marginRight: 'auto', display: 'block', marginTop: '10px' }} />

              <Card.Body>
                <Card.Title className='text-center'><b>Kaveri</b></Card.Title>
                <Card.Text className='mt-2'>
                Really appreciate and value the support and guidance provided. At the time of the pandemic when none of the doctors were available, I met Doctor. Even in the most stressful times, he has listened to me patiently and with all his attention and then provided valuable advice/treatment.
                </Card.Text>

              </Card.Body>
            </Card>
          </div>
        </Carousel.Item>
      </Carousel>
    </>
  )
}

export default Testmonialss