import React from 'react'

const Dr_Kalyan_Chakravarthy_Koganti = () => {
  return (
    <>
      <div id='Topmargin'></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{ textTransform: 'uppercase' }}>Dr Kalyan Chakravarthy Koganti</h1>
          </div>
          <ul classname="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>

          </ul>
        </div>
      </div>
      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#ff7800" }}>Dr KALYAN CHAKRAVARTHY KOGANTI</span>
            </h1>
            <p style={{ textAlign: 'justify' }}>
              {/* Graduation from Guntur Medical College and Post Graduation from Manipal Academy of Higher Education. 
                  Awarded PG certificate in Infectious diseases from London School of Hygiene and Tropical Medicine, UK and is the 1st ID specialist of our state. */}
              Graduation from Guntur Medical College and Post Graduation from Manipal Academy of Higher Education.
              Awarded PG certificate in Infectious diseases from London School of Hygiene and Tropical Medicine, UK and is the 1st ID specialist of our state.
            </p>
            <p style={{ textAlign: 'justify' }}>
              Started Center for Infectious Diseases at Help hospital in Guntur with state of the art Micobiology lab supported with automated culture systems, PCR and CB-NAAT technologies.
            </p>
            <p style={{ textAlign: 'justify' }}>
              In Dec 2019, with the help of young and enthusiastic super specialists, started Samishta Hospital and Research Institute, SHRI, which has first state of the art critical care unit in AP with isolation cubicles supported by air handling units.
            </p>

            {/* <p style={{ textAlign: 'justify' }}>
            Started Center for Infectious Diseases in Guntur with state of the art Micobiology lab supported with automated culture systems, PCR and CB-NAAT technologies.
            </p>
            <p style={{ textAlign: 'justify' }}>
            He is also pioneer in initiating first of its kind integrated health care with remote patient monitoring .Health ATM is his brain child that helps to monitor the patients 24/7 even from home or any other remote location.
            </p> */}
          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
            <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body rounded" style={{ height: '300px', borderRadius: '10px', width: '300px', boxShadow: 'grey' }} id="ServicesImG" src='KalyanChakravarthiImage.jpg' alt=''></img>
          </div>
        </div>
        <p style={{ textAlign: 'justify' }}>
          SHRI hospital has dedicated fever clinic with first multiplex PCR to detect severe infections in just 3 hours. Also, the critical care unit at SHRI, has an integrated technology, LIVE, that continuously monitors the patients using machine learning and AI.
        </p>
        <p style={{ textAlign: 'justify' }}>
          Presented papers at various international conferences on infectious diseases and Antimicrobial Resistance, the best being, the role of APTT in avoiding platelet transfusions in Dengue presented at ECCMID 2017 and COVID severity index at ESICM,2022.
        </p>
      </div>
    </>
  )
}

export default Dr_Kalyan_Chakravarthy_Koganti