import React from 'react'

const Images = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Images</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <img src='\IMG-20240130-WA0002.jpg' alt='' style={{width:'100%'}}></img>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <img src='\IMG-20240130-WA0003.jpg' alt='' style={{width:'100%'}}></img>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <img src='\IMG-20240130-WA0004.jpg' alt='' style={{width:'100%'}}></img>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <img src='\IMG-20240130-WA0005.jpg' alt='' style={{width:'100%'}}></img>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <img src='\IMG-20240130-WA0006.jpg' alt='' style={{width:'100%'}}></img>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <img src='\IMG-20240130-WA0007.jpg' alt='' style={{width:'100%'}}></img>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <img src='\IMG-20240130-WA0008.jpg' alt='' style={{width:'100%'}}></img>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <img src='\IMG-20240130-WA0009.jpg' alt='' style={{width:'100%'}}></img>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <img src='\IMG-20240130-WA0010.jpg' alt='' style={{width:'100%'}}></img>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <img src='\IMG-20240130-WA0011.jpg' alt='' style={{width:'100%'}}></img>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-3 mb-2'>
                        <img src='\IMG-20240130-WA0012.jpg' alt='' style={{width:'100%'}}></img>
                    </div>
                 
                    {/* <h3 style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'20%',marginBottom:'20%'}}>Coming Soon....</h3> */}
                </div>
            </div>
        </>
    )
}

export default Images