import React from 'react'

const Friday_Id_Message_Resistance_of_Antibiotics = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row mt-5'>
                    <div className='col-md-7 col-sm-12'>
                        <p style={{ textAlign: 'justify' }}>Susceptibility of an antibiotic must be well understood before selecting it based on culture reports.  </p>
                        <p style={{ textAlign: 'justify' }}>We must always consider intrinsic resistance of antibiotics while selecting them. </p>         
                        <p style={{ textAlign: 'justify' }}>For eg; Fosfomycin in Acinetobacter or Tigecycline in Psueudomonas have no role due to the above factor.</p>
                        <p style={{ textAlign: 'justify' }}>Also, it must be based on invivo rather than invitro susceptibility. </p>
                        <p style={{ textAlign: 'justify' }}>For eg; Daptomycin cannot be used in pneumonia due to inactivation by surfactant or Tigecycline cannot be used in bacteremia due to poor serum concentrations.</p>
                    </div>
                    <div className='col-md-5 col-sm-12'>
                        <img src='web.whatsapp1.png' style={{ width: '100%' }}></img>
                    </div>
                      <img src='resistanceIdImage.png' alt=''></img>
                </div>
            </div>
        </>
    )
}

export default Friday_Id_Message_Resistance_of_Antibiotics