import React from 'react'
import { Link } from 'react-router-dom'


const Patients_in_remote_areas_being_treated_through_smart_ICU_programme_in_Guntur = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Patients in remote areas being treated through smart ICU programme in Guntur</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-2 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">

                        <p><b>This is the first of its kind critical care programme in the country and has been piloted by “SHRI” hospital, Guntur as part of social responsibility, said an official</b></p>
                        <p>A young female, with chronic lung disease requiring frequent admissions at higher centres in a critical state due to infection, has been cost effectively managed in a limited resource setting at the government area hospital Chilakaluripet, with the help of LIVE, a pilot smart ICU programme launched a few months ago, informed Dr. Kalyan Chakravarthy, Director of SHRI hospital, Guntur. </p>
                        <p>Addressing a press conference at Chilakaluripet Area Hospital along with the government doctors on Wednesday, he said tha it is a hub and spoke model, in which the experts are located at the SHRI hospital - hub and patients are at the area hospital Chilakaluripet - the spoke.</p>
                        <p>He informed that this is the first of its kind critical care programme in the country and has been piloted by “SHRI” hospital, Guntur as part of social responsibility.</p>
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 mt-2">
                        <img className=" img-thumbnail shadow-lg p-2 mb-5 bg-body rounded" style={{ height: '200px', borderRadius: '10px', width: '300px', boxShadow: 'grey' }} id="ServicesImG" src='Patients-in-remote-area.jpeg' alt=''></img>
                        <div style={{marginTop:'-3%'}} className='mb-2'>
                            <img src='The-Hindu-Logo1.jpg' alt='' style={{ width: '70px', height: '40px' }}></img>
                            <a className='p-3'><Link to='https://www.thehindu.com/news/national/andhra-pradesh/patients-in-remote-areas-being-treated-through-smart-icu-programme-in-guntur/article67772958.ece'> Aritical In The Hindu</Link></a>

                        </div>

                        <a style={{ textDecoration: 'none' }} href='https://www.thehindu.com/news/national/andhra-pradesh/patients-in-remote-areas-being-treated-through-smart-icu-programme-in-guntur/article67772958.ece'><p style={{ color: 'red' }}>Dr. Kalyan Chakravarthy, Director of SHRI hospital, along with Superintendent of Chilakaluripet Area Hospital Lakshmi Kumari and others addressing the media on Wednesday. </p></a>
                    </div>
                    <p>Dr. Kalyan said that not just this single patient, 24 similar patients, who otherwise would have been referred to higher centres have been treated and recovered with the help of this innovative project, which proved to be a game-changer in rural critical care settings. </p>
                    <p>Once the patient enters the ICU and as soon as credentials are entered, their health condition is immediately captured by this smart ICU and the data of their clinical status is continuously streamed to the hub which is a tertiary care centre.</p>
                    <p>The super-specialists at the hub can evaluate the data that is being streamed and make suggestions live to the medical team attending the patient bedside at the rural hospital.</p>
                    <p>Also, there is an inbuilt machine learning and AI model called deterioration index, which helps in identifying patients who are worsening earlier than they are actually recognised by clinical evaluation rounds. </p>
                    <p>More importantly, entire patient data is saved digitally in a structured manner, which can be obtained for further reference at any time, Dr. Kalyan added.</p>

                </div>

            </div>

        </>

    )
}

export default Patients_in_remote_areas_being_treated_through_smart_ICU_programme_in_Guntur