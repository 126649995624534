import React from 'react'

const Friday_Id_Message_Rabies_immunoglobulin_RIG = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row mt-5'>
                    <div className='col-md-7 col-sm-12'>
                        <p style={{ textAlign: 'justify' }}>Rabies immunoglobulin(RIG) has to be given equal importance along with vaccination for post exposure prophylaxis(PEP) in Category 3 exposure that includes transdermal scratches/bites, contamination of mucous membranes or even animal licks on broken skin.</p>
                        <p style={{ textAlign: 'justify' }}>We had either equine RIG or human RIG but both of them had their own limitations. First one had significant hypersensitivity and the second one had insufficient availability and high cost. </p>
                        <p style={{ textAlign: 'justify' }}> But, the availability of monoclonal antibodies(mAbs) over the last few years appears to be a game changer and have proven to be the best among all PEP. </p>
                        <p style={{ textAlign: 'justify' }}>Rabishield and Twinrab are the two brands available in our country. </p>
                        <p style={{ textAlign: 'justify' }}>RIG must be given immediately along with vaccine with a different syringe. Majority of it must be infiltrated at the site of the wound, if any remaining must be given at a site distant from the wound, IM. </p>

                    </div>
                    <div className='col-md-5 col-sm-12'>
                        <img src='web.whatsapp1.png' style={{ width: '100%' }}></img>
                    </div>
                        <p style={{ textAlign: 'justify' }}>As it takes some time for active immunity to be triggered after the vaccine, passive immunization with RIG neutralises virus initially. </p>
                     
                    <p style={{ textAlign: 'justify' }}>RIG preferably to be given along with first dose of vaccine on day 0, it can it can also be given later but not  after 1 week as it may suppress the developing immune response due to the vaccine. </p>
                        <p style={{ textAlign: 'justify' }}>Regular vaccination in an immunocompetent needs 4 doses on 0,3,7,14 while an additional dose on day 28 for immunosuppressed. </p>
                        <p style={{ textAlign: 'justify' }}>For those who previously had PEP, RIG isn’t indicated. </p>
                        <p style={{ textAlign: 'justify' }}>Also, two vaccine doses on day 0 and 3 are sufficient.</p>

                </div>
            </div>
        </>
    )
}

export default Friday_Id_Message_Rabies_immunoglobulin_RIG