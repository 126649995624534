import React from 'react'

const Best_General_Medicine_Hospitals_In_Guntur = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Best General Medicine Hospitals In Guntur</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>


            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#ff7800" }}>Best General Medicine Hospitals In Guntur</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            At Help Hospital, we understand that your health is your most precious asset. That's why we've dedicated ourselves to providing top-quality general medicine services in Guntur, and we're proud to introduce you to our specialist, Dr. Kalyan Chakravarthy Koganti.
                        </p>
                        <p style={{ textAlign: 'justify' }}>
                            Meet Our Specialist: Dr. Kalyan Chakravarthy Koganti, M.D. (Gen), PG Cert. (Infectious Diseases)
                        </p>
                        <p>Dr. Kalyan Chakravarthy Koganti is a renowned specialist in the field of general medicine, with a particular focus on sepsis and infectious diseases. With an M.D. in General Medicine from Manipal University and a PG Certificate in Infectious Diseases from LSHTM, UK, Dr. Koganti brings a wealth of knowledge and expertise to Help Hospital.</p>
                        {/* <p style={{ textAlign: 'justify' }}>
            Symptoms Of Fever Include:
            </p>
            <ul>
              <li>Hyperalgesia.</li>
              <li>Weakness/ lethargy.</li>
              <li>Sweating.</li>
              <li>Sleepiness.</li>
            </ul> */}


                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='General-Medicine.png' alt=''></img>
                    </div>
                </div>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>Why Choose Help Hospital for General Medicine and Infectious Diseases Care?</span>
                </h4 >

                <p>Exceptional Expertise: Dr. Koganti's specialized training in infectious diseases makes him a valuable resource for patients in need of diagnosis and treatment for a wide range of infections.</p>
                <p>Cutting-Edge Technology: Help Hospital is equipped with state-of-the-art medical technology to ensure accurate diagnostics and effective treatment.</p>
                <p>Comprehensive Care: Our hospital offers a wide range of general medicine services, ensuring that all your healthcare needs are met under one roof.</p>
                <p>Patient-Centered Approach: We understand that every patient is unique. Dr. Koganti and our entire team take a patient-centered approach, tailoring treatment plans to meet your individual needs.</p>
                <p>Commitment to Excellence: We are committed to providing the highest standard of medical care in Guntur. Your health and well-being are our top priorities.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>Conditions We Specialize In:</span>
                </h4 >
                <p>Sepsis: Dr. Koganti is an expert in diagnosing and managing sepsis, a life-threatening condition that requires immediate attention. We ensure a prompt response to sepsis cases.</p>
                <p>Infectious Diseases: From common infections to rare and complex cases, our hospital is equipped to diagnose and treat various infectious diseases.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>Your Health, Our Priority</span>

                </h4 >
                <p>At Help Hospital, your health is our priority. We are dedicated to ensuring that you receive the best general medicine and infectious diseases care in Guntur. Dr. Kalyan Chakravarthy Koganti and our team are here to provide you with expert guidance and compassionate care. Whether you're dealing with a general health concern or a complex infectious disease, we have the knowledge, experience, and technology to help you on your path to recovery.</p>
                <p>Contact us today to schedule an appointment with Dr. Koganti or to learn more about the comprehensive medical services we offer at Help Hospital. Your health matters, and we're here to support you every step of the way.</p>
            </div>
        </>
    )
}

export default Best_General_Medicine_Hospitals_In_Guntur