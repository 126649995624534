import React from 'react'

const Videos = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Videos</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>
            <div className='container'>
                <div className='row mt-5'>
                    <div className='col-md-3 col-sm-12 mb-4'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/dig6lF-oF2I?si=3psx3eslAKsiysOw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }}></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mb-4'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/QdQU-lnnquk?si=2ibLRWg-53kFo4eH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }}></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mb-4'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/ZV6G0AUOllc?si=TnJsN4TXx1nXEYLa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }}></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mb-4'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/-5JMpg0QVPw?si=wjtNM05RUh0kzZ3d" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullscreen style={{ borderRadius: '10px' }}></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mb-4'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/_62BLWoCLMk?si=ZEBNTE1eq8K_cA-6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }}></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mb-4'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/SmhcR30U6Sc?si=r75IKFP6JmkwZV-m" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }}></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mb-4'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/GpHGc1Gf6Bk?si=lUKPs8c925HesaGO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }}></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mb-4'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Gpiy3wUWdnM?si=_aInW6zJV9OzuVnU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }}></iframe>                    </div>
                    <div className='col-md-3 col-sm-12 mb-4'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/G_HaLRlm7EY?si=fzULjpYKbZ_xJJwl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }}></iframe>                    </div>
                    <div className='col-md-3 col-sm-12 mb-4'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Qdv01fqs5EU?si=KyjNQMftWmAbQrYP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }}></iframe>                    </div>
                    <div className='col-md-3 col-sm-12 mb-4'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/z0kSCfGxvg0?si=6Ztu5jCF1FxjlqrY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }}></iframe>                    </div>
                    <div className='col-md-3 col-sm-12 mb-4'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Gz-2yHN1RsI?si=XtDuVPKo28vV1-ep" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }}></iframe>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Videos