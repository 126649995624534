import React from 'react'
import { Link } from 'react-router-dom'


const Friday_Id = () => {
  return (
    <>
      <div id='Topmargin'></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
          </ul>
        </div>
      </div>
      <div className='container'>
        <div className='row mt-5'>
          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Message_Sepsis_Fact' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase' }} className='mb-4 text-center'>Friday Id Message Sepsis Facts</a>
            </div>
          </div>
          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Message_Procalcitonin_is_a_biomarker_for_systemic_bacterial_infections' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase', marginTop: '-10px' }} className='mb-2 text-center'>Procalcitonin is a biomarker for systemic bacterial infections</a>
            </div>
          </div>
          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Message_Patients_with_sepsis_induced_hypoperfusion_or_shock' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase', marginTop: '-10px' }} className='mb-2 text-center'>Patients with sepsis induced hypoperfusion or shock</a>
            </div>
          </div>
          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Message_Patients_with_sepsis_induced_shock' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase', marginTop: '-10px' }} className='mb-2 text-center'>Patients with sepsis induced shock</a>
            </div>
          </div>

        </div>
      </div>


      {/* //second ane cards */}

      <div className='container'>
        <div className='row mt-5'>
          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Message_Patients_with_sepsis_or_septic_shock' className='mb-2 text-center' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase', marginTop: '-10px' }}>Patients with sepsis or septic shock</a>
            </div>
          </div>

          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_In_patients_with_probable_sepsis' className='mb-3 text-center' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase' }}>In patients with probable sepsis</a>
            </div>
          </div>

          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Message_In_view_of_rising_multi_drug_resistance_MDR' className='mb-2 text-center' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase', marginTop: '-13px' }}>In view of rising multi drug resistance (MDR) </a>
            </div>
          </div>

          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Message_Anamnestic_response' className='mb-2 text-center' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase', }}>Anamnestic response</a>
            </div>
          </div>
        </div>
      </div>


      {/* third row */}

      <div className='container'>
        <div className='row mt-5'>
          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Message_Lucidly_Integrated_Vitalcare_in_Emergency_LIVE' className='mb-2 text-center' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase', marginTop: '-15px' }}>Lucidly Integrated Vitalcare in Emergency - LIVE</a>
            </div>
          </div>

          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Dengue_virus' className='mb-2 text-center' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase' }}>Dengue Virus</a>
            </div>
          </div>

          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Orientia_tsutsugamushi' className='mb-2 text-center' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase' }}>Orientia tsutsugamushi</a>
            </div>
          </div>

          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Message_Candiduria' className='mb-2 text-center' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase' }}>Candiduria</a>
            </div>
          </div>
        </div>
      </div>

      {/* Fourth row */}

      <div className='container'>
        <div className='row mt-5'>
          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Message_Diabetes_related_foot_infections_DFI' className='mb-2 text-center' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase' }}>Diabetes related foot infections(DFI)</a>
            </div>
          </div>

          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Messages_WHO_Global_Influenza_Surveillance_And_Response_System_GISRS' className='mb-2 text-center' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase', marginTop: '-30px' }}>WHO Global Influenza Surveillance & Response System(GISRS) </a>
            </div>
          </div>

          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Message_Rabies_immunoglobulin_RIG' className='mb-3 text-center' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase' }}>Rabies immunoglobuan(RIG)</a>
            </div>
          </div>

          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Message_Microbiome' className='mb-3 text-center' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase' }}>Microbiome</a>
            </div>
          </div>


        </div>
      </div>


      {/* Fifth row */}

      <div className='container'>
        <div className='row mt-5'>
          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Message_Unlike_PEP_post_exposure_prophylaxis_for_HIV' className='mb-2 text-center' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase', marginTop: '-20px' }}>. Unlike PEP(post exposure prophylaxis) for HIV</a>
            </div>
          </div>

          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Message_world_HIV_day' className='mb-2 text-center' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase' }}>World HIV day</a>
            </div>
          </div>

          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Message_RSV_Respiratory_Syncytial_Virus' className='mb-2 text-center' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase' }}>RSV(Respiratory Syncytial Virus)</a>
            </div>
          </div>

          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Shortly_on_the_present_scenario_on_COVID_19' className='mb-2 text-center' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase', marginTop: '-20px' }}>Shortly on the present scenario on COVID-19.</a>
            </div>
          </div>
        </div>
      </div>



      {/* Sixth row */}

      <div className='container'>
        <div className='row mt-5'>
          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Message__MIC_Minimum_Inhibitory_Concentration' className='mb-2 text-center' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase', marginTop: '-20px' }}>MIC, Minimum Inhibitory Concentration</a>
            </div>
          </div>

          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Message_Resistance_of_Antibiotics' className='mb-2 text-center' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase' }}>Resistance Of Antibiotics</a>
            </div>
          </div>

          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{ height: '100%' }}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Message_VRE' className='text-center' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase', marginTop: '-18px' }}>VRE(Vancomycin Resistant Enterococci)</a>
            </div>
          </div>

          <div className='col-md-3 col-sm-12 p-2'>
            <div className='card' style={{height:'100%'}}>
              <img src='web.whatsapp.png' alt=''></img>
              <a href='/Friday_Id_Message_Recent_National_Conference_On_HIV' className='mb-2 text-center' style={{textDecoration:'none',color:'rgb(13, 56, 67)',textTransform:'uppercase',marginTop:'-20px'}}>Recent National Conference on HIV</a>
            </div>
          </div> 
        </div>
      </div>
    </>
  )
}

export default Friday_Id