import React from 'react'

const Friday_Id_Message_Microbiome = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row mt-5'>
                    <div className='col-md-7 col-sm-12'>
                        <p style={{ textAlign: 'justify' }}>Microbiome- All living microbes living in the host, gut, skin, mouth, lung.</p>
                        <p style={{ textAlign: 'justify' }}>40 trillion microorganisms and 1000 different bacterial taxa are living in the gut lumen symbiotically, with 100 fold more genes than human genome. </p>
                        <p style={{ textAlign: 'justify' }}>What happens after giving antibiotics?</p>
                        <p style={{ textAlign: 'justify' }}>A study in ICU patients revealed that a single bacterial genus make up more than 50% of the gut microbiota. </p>
                        <p style={{ textAlign: 'justify' }}>Within less than 6 hrs to ICU admission and antibiotic administration, dysbiosis starts leading to impaired host immune response and susceptibility to nosocomial infections. </p>
                        <p style={{ textAlign: 'justify' }}>Probiotics, prebiotics and synbiotics(combination of pro and pre) are being given to improve good bacteria. </p>
                        <p style={{ textAlign: 'justify' }}>Meta analysis proved that there isn’t any mortality benefit except for reduction in LOS or incidence of VAP or mech. ventilation in few RCT’s. </p>
                                            

                    </div>
                    <div className='col-md-5 col-sm-12'>
                        <img src='web.whatsapp1.png' style={{ width: '100%' }}></img>
                    </div>
                    <p style={{ textAlign: 'justify' }}>FMT(Fecal Microbiota Transplant) is transfer of intestinal microorganisms from a healthy donor via an infusion of liquid suspension of stool and restore the normal micro environment in a person with altered gut microbiota. </p>
                        <p style={{ textAlign: 'justify' }}>FMT was superior to Vancomycin in resolving Clostridium difficile in various meta analysis, but a similar attempts in other ICU infections isn’t yet successful completely. </p>
                        <p style={{ textAlign: 'justify' }}>Microbiome plays an important role in futuristic approach to tackle AMR(Anti microbial resistance)</p>

                </div>
            </div>
        </>
    )
}

export default Friday_Id_Message_Microbiome