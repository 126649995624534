import React from 'react'

const Sepsis_and_Infectious_Diseases = () => {
  return (
    <>
      <div id='Topmargin'></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{ textTransform: 'uppercase' }}>SEPSIS AND INFECTIOUS DISEASES</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
          </ul>
        </div>
      </div>

      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '23px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#ff7800" }}>Best Doctor for Sepsis in Guntur at SHRI Hospital</span>
            </h1>
            <p style={{ textAlign: 'justify' }}>
              Dr. Kalyan Chakravarthy Koganti, a highly skilled and compassionate medical professional, has gathered a team of exceptional doctors specializing in critical care and infectious diseases at SHRI Hospital in Guntur. With a focus on providing top-notch medical services, Dr. Koganti and his team are dedicated to delivering the best care possible for patients suffering from sepsis.            </p>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(245, 41, 41)" }}>Our Commitment to Sepsis Care</span>
            </h4 >
            <p style={{ textAlign: 'justify' }}>
              At SHRI Hospital, we recognize the urgency and severity of sepsis, a life-threatening condition caused by the body's response to infection. Our team of expert doctors for sepsis is committed to promptly identifying and treating septic patients to improve their chances of recovery.            </p>
          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
            <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='sepsis1.jpg' alt=''></img>
          </div>
        </div>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>Why Choose SHRI Hospital for Sepsis Treatment?</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}>
          <b>Specialized Expertise:</b> Our team of doctors possesses specialized expertise in critical care, infectious diseases, and sepsis management. With years of experience, they are well-equipped to handle even the most complex cases.
        </p>
        <p style={{ textAlign: 'justify' }}>
          <b>State-of-the-Art Facilities:</b> SHRI Hospital boasts modern infrastructure and advanced medical technology to ensure accurate diagnosis and effective treatment of sepsis.
        </p>
        <p style={{ textAlign: 'justify' }}>
          <b>Prompt Diagnosis:</b> Early detection is crucial in sepsis cases. Our doctors utilize cutting-edge diagnostic methods to swiftly identify sepsis, enabling rapid initiation of treatment.
        </p>
        <p style={{ textAlign: 'justify' }}>
          <b>Comprehensive Care:</b> We provide comprehensive care for sepsis patients, considering all aspects of their condition to devise personalized treatment plans.
        </p>
        <p style={{ textAlign: 'justify' }}>
          <b>Collaborative Approach:</b> Our doctors work collaboratively, combining their skills and knowledge to deliver the best possible outcomes for sepsis patients.
        </p>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>Services Offered for Sepsis Management</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}>
        <b>Rapid Diagnosis:</b> Our advanced diagnostic capabilities aid in quickly identifying sepsis, allowing for immediate intervention.
        </p>
        <p style={{ textAlign: 'justify' }}>
        <b>Intensive Care:</b> SHRI Hospital is equipped with a state-of-the-art Intensive Care Unit (ICU), providing 24/7 monitoring and critical care for sepsis patients.
        </p>
        <p style={{ textAlign: 'justify' }}>
        <b>Antibiotic Stewardship:</b> Our doctors practice responsible antibiotic use, ensuring the most effective treatment while minimizing the risk of antibiotic resistance.
        </p>
        <p style={{ textAlign: 'justify' }}>
        <b>Multidisciplinary Care:</b> We adopt a multidisciplinary approach, involving various medical specialists to address all aspects of sepsis management.
        </p>
        <p style={{ textAlign: 'justify' }}>
        <b>Supportive Therapy:</b> Our team provides supportive therapies to stabilize patients and improve their overall condition.
        </p>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>Contact Us</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}>
        If you or a loved one is battling sepsis, trust the expertise of our exceptional doctors at SHRI Hospital, led by Dr. Kalyan Chakravarthy Koganti.
        </p>
      </div>

    </>
  )
}

export default Sepsis_and_Infectious_Diseases