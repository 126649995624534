import React from 'react'

const lucidly_Integrated_Vital_Care_In_Emergency_in_Guntur = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Lucidly Integrated Vital Care In Emergency (LIVE) </h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ fontSize: '28px ', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#ff7800" }}>Welcome to Lucidly Integrated Vital Care In Emergency (LIVE) at SHRI Hospital</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            LIVE, Lucidly Integrated Vital Care In Emergency is another innovation under the leadership of Dr. Kalyan Chakravarthi at Shri Hospital. Using the most modern technology its more than just a Tele-ICU. Patient’s data is captured LIVE and using machine learning and artificial intelligence their healthcare statistics are constantly updated to not only intermediate but also all the important specialisations associated with patient care.
                        </p>
                        <p style={{ textAlign: 'justify' }}>
                            Dr. Kalyan Chakravarthy Koganti, a renowned and compassionate medical professional, has brought his visionary expertise to Guntur with the establishment of Lucidly Integrated Vital Care In Emergency (LIVE) at SHRI Hospital. With a commitment to providing exceptional emergency medical care, Dr. Koganti aims to revolutionize emergency healthcare services in the region.                </p>
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='LIVE V2.jpg' alt=''></img>
                    </div>
                </div>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Our Vision</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>
                    At LIVE, our vision is to set new standards in emergency medical care. We aim to create a state-of-the-art facility that prioritizes patient well-being, reduces response times, and ensures seamless integration of various medical specialties in critical situations. Our goal is to be a beacon of hope and healing for those in need during emergency situations.                </p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Our Mission</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>
                    Our mission is to save lives and enhance the quality of emergency medical care by combining cutting-edge technology, expert medical professionals, and a patient-centric approach. We are dedicated to providing rapid, accurate, and compassionate care to patients during their most vulnerable moments.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Services Offered at LIVE</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>
                    <b>24/7 Emergency Care :</b> LIVE operates round-the-clock, ensuring that expert medical care is available whenever you need it the most.
                </p>



                <p style={{ textAlign: 'justify' }}>
                    <b>Multi-Specialty Integration :</b> Our team of highly skilled medical specialists, including emergency medicine physicians, trauma surgeons, anesthesiologists, and critical care experts, collaborate seamlessly to provide comprehensive care to critically ill and injured patients.
                </p>
                <p style={{ textAlign: 'justify' }}>
                    <b>Advanced Technology :</b> LIVE is equipped with the latest medical technology and diagnostic equipment, enabling quick and accurate assessments and interventions.
                </p>
                <p style={{ textAlign: 'justify' }}>
                    <b>Trauma Care:</b> We have a dedicated trauma center to handle all types of traumatic injuries, ensuring prompt and efficient treatment.
                </p>
                <p style={{ textAlign: 'justify' }}>
                    <b>Cardiac Emergencies:</b> With a focus on cardiac emergencies, we provide immediate and effective care for heart attack patients.
                </p>

                <p style={{ textAlign: 'justify' }}>
                    <b> Stroke Care:</b> Our team is trained to respond swiftly to stroke cases, optimizing the chances of a successful recovery.
                </p>
                <p style={{ textAlign: 'justify' }}>
                    <b>Pediatric Emergencies:</b> We have a specialized team to handle pediatric emergencies with utmost care and empathy.
                </p>
                <p style={{ textAlign: 'justify' }}>
                    <b> Ambulance Services:</b> Our ambulance fleet is well-equipped and staffed by trained paramedics, ensuring safe transportation to the hospital.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Why Choose LIVE at SHRI Hospital?</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>
                    <b>Expert Care:</b> Our team consists of highly experienced and skilled medical professionals with expertise in handling critical emergencies.
                </p>
                <p style={{ textAlign: 'justify' }}>
                    <b>Comprehensive Approach:</b> We provide a holistic approach to emergency care, ensuring all aspects of the patient's condition are addressed.
                </p>
                <p style={{ textAlign: 'justify' }}>
                    <b>Patient-Centric:</b> At LIVE, patients are at the heart of everything we do. We prioritize their well-being and comfort throughout their stay.
                </p>
                <p style={{ textAlign: 'justify' }}>
                    <b>Fast Response:</b> Our streamlined processes and efficient communication enable us to respond swiftly to emergencies.
                </p>
                <p style={{ textAlign: 'justify' }}>
                    <b> State-of-the-Art Facility:</b> LIVE operates within the modern infrastructure and advanced medical technology of SHRI Hospital, enhancing the level of care we deliver.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Contact Us</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>
                    In case of any medical emergency, don't hesitate to reach out to Lucidly Integrated Vital Care In Emergency (LIVE) at SHRI Hospital.        </p>
            </div>

        </>
    )
}

export default lucidly_Integrated_Vital_Care_In_Emergency_in_Guntur