import React from 'react'

const Friday_Id_Message_world_HIV_day = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row mt-5'>
                    <div className='col-md-7 col-sm-12'>
                        <p style={{ textAlign: 'justify' }}>On this world HIV day,</p>
                        <p style={{ textAlign: 'justify' }}>Let’s recap recent updates..</p>
                        <p style={{ textAlign: 'justify' }}>1) Complications due to HIV are moving from being infectious to metabolic with the advent of newer antiretrovirals(ARV)</p>
                        <p style={{ textAlign: 'justify' }}>Obesity, DM, dyslipidemia are on the rise in HIV population, which requires continuous monitoring like any other metabolic illness. </p>
                        <p style={{ textAlign: 'justify' }}>2) Though it has been well understood now over a decade, undetectable viral loads means untransmissible infection, it hasn’t been well accepted. </p>
                        <p style={{ textAlign: 'justify' }}>For eg; there is no need for any post exposure prophylaxis(PEP), if we are sure that the viral load of the patient is undetectable. </p>

                    </div>
                    <div className='col-md-5 col-sm-12'>
                        <img src='web.whatsapp1.png' style={{ width: '100%' }}></img>
                    </div>
                        <p style={{ textAlign: 'justify' }}>3) CD4 is no longer required to be done regularly if the patient’s viral load(VL) is undetectable. If the patient is quite compliant with medication even VL can be done once a year if undetectable. </p>
                        <p style={{ textAlign: 'justify' }}>4) We needn’t wait for any reports(VL/CD4) and must initiate ARV as soon as diagnosis is confirmed, except in few rare situations like TB meningitis or Cryptococcal meningitis, where concomitant treatment needs to be advocated under supervision. </p>
                        <p style={{ textAlign: 'justify' }}>5) PrEP(Pre exposure prophylaxis) must be advocated and encouraged in all those people who have high risk behaviour or HIV discordant couples. </p>
                        <p style={{ textAlign: 'justify' }}>Finally, there aren’t any lines(1st line, 2nd line and so on) presently and all the patients will be initially offered the best available three drug regimen(Integrase inhibitor based). Also, if the patients are doing well with persistent undetectable viral loads, we can shift to two drug regimen as well.</p>

                </div>
            </div>
        </>
    )
}

export default Friday_Id_Message_world_HIV_day