import React from 'react'

const Friday_Id_Message_Procalcitonin_is_a_biomarker_for_systemic_bacterial_infections = () => {
  return (
    <>
      <div id='Topmargin'></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
          </ul>
        </div>
      </div>

      <div className='container'>
        <div className='row mt-5'>
          <div className='col-md-7 col-sm-12'>
            <p style={{ textAlign: 'justify' }}>Procalcitonin is a biomarker for systemic bacterial infections, particularly sepsis. </p>
            <p style={{ textAlign: 'justify' }}>It’s useful in differentiating viral from bacterial sepsis. It’s normal or only slightly elevated in viral infections unlike in bacterial infections</p>
            <p style={{ textAlign: 'justify' }}>Though it can be sometimes elevated in non-infectious inflammation, it is particularly useful in guiding antibiotic therapy in the era of multi drug resistance. </p>
            <p style={{ textAlign: 'justify' }}>If the PCT levels are decreased by 80% from peak, we can stop antibiotics without considering the no.of days of they were being used. </p>
            <p style={{ textAlign: 'justify' }}>This particularly helps in avoiding new infection by multi drug resistant organism/C.diificile. </p>
            <p style={{ textAlign: 'justify' }}>It has been proven that it shortens the length of antibiotic therapy and also reduces the mortality at 28 days and also after 6 months in one of the biggest trials, PROGRESS.</p>
           
          </div>
          <div className='col-md-5 col-sm-12'>
            <img src='web.whatsapp1.png' style={{ width: '100%' }}></img>
          </div>
        </div>
      </div>
    </>
  )
}

export default Friday_Id_Message_Procalcitonin_is_a_biomarker_for_systemic_bacterial_infections