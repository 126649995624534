import React from 'react'
import { Link } from 'react-router-dom'

const Friday_Id_Message_Sepsis_Fact = () => {
  return (
    <>
      <div id='Topmargin'></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
          </ul>
        </div>
      </div>

      <div className='container'>
        <div className='row mt-5'>
          <div className='col-md-7 col-sm-12'>
            <p style={{ textAlign: 'justify' }}>At the outset, we thank you for being part of our journey in fighting sepsis/infections.  Sepsis being the leading cause of mortality/morbidity, we would like to bring forward important literature/updates to you once in a week as part of our academic initiative-  FRIDAY- ID DAY.  Hope this would be useful and we would be extremely happy for any suggestions. Also, available round the clock on this no. to provide the best possible response with respect to Sepsis/infections.  Thank you, Dr. Kalyan Chakravarthy  Help Hospital SHRI hospital</p>
            <p style={{ textAlign: 'justify' }}>qSOFA is a quick screening tool to suspect someone in sepsis though not to detect/confirm.
              qSOFA has good specificity but less sensitivity which improves with adding lactate to it.
              For more eloborate explantion, pls click the below link
            </p>
            <Link to='https://shrihospital.com/SEPSIS%20FACTS.html'>https://shrihospital.com/SEPSIS%20FACTS.html</Link>
          </div>
          <div className='col-md-5 col-sm-12'>
            <img src='web.whatsapp1.png' style={{width:'100%'}}></img>
          </div>
        </div>
      </div>
    </>
  )
}

export default Friday_Id_Message_Sepsis_Fact