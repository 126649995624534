import React from 'react'

const Any_Time_Monitoring = () => {
    return (
        <>
    
        <div style={{ height: '120px' }}></div>
        <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
          <div className="col text-center strip1" style={{ color: 'white' }}>
            <div className="row mt-4" >
              <h1 style={{ textTransform: 'uppercase' }}>Any Time Monitoring(ATM)</h1>
            </div>
            <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
              <li ><a href="/home">Home</a></li>
            </ul>
          </div>
        </div>
        <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
          <div className="row ">
            <div className="col-lg-7 mb-2">
              <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                <span style={{ color: "rgb(245, 41, 41)" }}>Any Time Monitoring(ATM)</span>
              </h1>
              <p style={{ textAlign: 'justify' }}>
              Any Time Monitoring is an innovative and pioneering healthcare management program at Help Hospital. It addresses the need for continuous monitoring and care for patients, particularly those dealing with Non-Communicable Diseases (NCDs) such as diabetes, hypertension, heart disease, kidney disease, and others.          </p>
              <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                <span style={{ color: "rgb(245, 41, 41)" }}></span>
              </h4 >
              <p style={{ textAlign: 'justify' }}>
              The program operates as an engagement platform that ensures patients are constantly monitored round the clock. It involves a dedicated team of physicians and nurses who utilize integrated specialty technology to monitor patients' health status and respond promptly to their needs. This comprehensive monitoring system covers a range of situations, from simple health concerns to emergencies.
              </p>
            
            </div>
            <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
              <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='atm.png' alt=''></img>
            </div>
          </div>
          <p style={{ textAlign: 'justify' }}>
              By implementing Any Time Monitoring, Help Hospital aims to bridge the gap between hospital visits and provide personalized care and support to patients in their own homes. The program allows for proactive management of chronic conditions, timely intervention in case of emergencies, and ensures that patients receive the attention they require at all times.
              </p>
              <p style={{ textAlign: 'justify' }}>
              If you have any further questions or would like more details about the Any Time Monitoring program or any other aspect of Help Hospital's services, please let me know.
              </p>
        
        </div>
      </>
      )
}

export default Any_Time_Monitoring