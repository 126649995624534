import React from 'react'

const Friday_Id_In_patients_with_probable_sepsis = () => {
    return (
        <>
          <div id='Topmargin'></div>
          <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
            <div className="col text-center strip1" style={{ color: 'white' }}>
              <div className="row mt-4" >
                <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
              </div>
              <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                <li ><a href="/home">Home</a></li>
              </ul>
            </div>
          </div>
    
          <div className='container'>
            <div className='row mt-5'>
              <div className='col-md-7 col-sm-12 p-2'>
                <p style={{ textAlign: 'justify' }}> In patients with probable sepsis, we need to obtain blood cultures before administering antibiotics. Scientifically  two sets of bottles, one aerobic and one anaerobic to be collected. Collecting atleast one set rather than single aerobic culture bottle is imperative. Present automated blood culture systems provide a positive signal as soon as growth is detected and with the help of molecular rapid diagnostic tests(mRDT), identification and antimicrobial resistance gene detection is possible in less than 3 hrs. Antimicrobial resistance gene testing adds extreme value to initiate appropriate therapy without significant delays. This gene testing is part of the presently available mRDT’s like Biofire(multiplex PCR with resistance gene detection like NDM, OXA-48 etc).</p>
                </div>
              <div className='col-md-5 col-sm-12' style={{justifyItems:'center'}}>
                <img src='web.whatsapp1.png' style={{ width: '80%'}}></img>
              </div>
            </div>
          </div>
        </>
      )
}

export default Friday_Id_In_patients_with_probable_sepsis