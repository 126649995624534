import React from 'react'

const Best_Fever_Hospital_in_guntur = () => {
  return (
    <>
      <div id='Topmargin'></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{ textTransform: 'uppercase' }}>Best Fever Hospital in Guntur with BioFire Technology</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
          </ul>
        </div>
      </div>

      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ fontSize: '23px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#ff7800" }}>Best Fever Hospital in Guntur with BioFire Technology</span>
            </h1>
            <p style={{ textAlign: 'justify' }}>
            SHRI, Fever Clinic is another initiative by Dr. Kalyan Chakravarthi at Shri Hospital Guntur it is a fully equipped centre that is backed up by the First negative prime ICU in AP, to support critical care patients who are in a very sick state. It is also supported by Bio-Fire, again the first multiplex PCR in AP to detect serious infection in 3 hours and help patients recover from critical conditions.
            </p>
            <p style={{ textAlign: 'justify' }}>
              Dr. Kalyan Chakravarthy Koganti, a distinguished and compassionate medical professional, has established SHRI Hospital in Guntur with a vision to provide exceptional healthcare services to the community. Driven by a passion for innovation and patient care, he has introduced state-of-the-art BioFire Technology to enhance the diagnosis and treatment of fever-related conditions.
            </p>
           
          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
            <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='fever.png' alt=''></img>
          </div>
        </div>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(245, 41, 41)" }}>Our Commitment to Excellence</span>
            </h4 >
            <p style={{ textAlign: 'justify' }}>
              At SHRI Hospital, we are committed to delivering the highest standard of healthcare to our patients. Our fever hospital is designed to cater specifically to individuals suffering from fever and other infectious conditions. With the implementation of cutting-edge BioFire Technology, we aim to revolutionize the way fever-related illnesses are diagnosed and treated.
            </p>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>Why Choose SHRI Hospital for Fever Care?</span>
        </h4 >
       
        <p style={{ textAlign: 'justify' }}>
        <b>BioFire Technology:</b> We take immense pride in being one of the few hospitals in Guntur equipped with BioFire Technology. This advanced diagnostic system allows for rapid and accurate identification of infectious agents, leading to timely and targeted treatment.
        </p>
        <p style={{ textAlign: 'justify' }}>
        <b>Comprehensive Fever Care:</b> Our medical team consists of highly skilled physicians and specialists with extensive experience in diagnosing and treating various fever-related conditions. We offer comprehensive care to ensure a speedy recovery.
          </p>
          <p style={{ textAlign: 'justify' }}>
          <b> State-of-the-Art Infrastructure:</b> SHRI Hospital is outfitted with modern infrastructure and advanced medical equipment, providing a comfortable and efficient healthcare environment.

          </p>
          <p style={{ textAlign: 'justify' }}>
          <b>Patient-Centric Approach: </b>We place our patients at the center of everything we do. Our healthcare professionals are compassionate, understanding, and dedicated to providing personalized care for each individual.
          </p>
          <p style={{ textAlign: 'justify' }}>
          <b>Collaborative Care:</b> Our team of experts collaborates seamlessly to develop individualized treatment plans, incorporating the latest medical advancements to achieve the best possible outcomes.
          </p>
          <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>Services Offered at SHRI Hospital</span>
        </h4 >
          <p style={{ textAlign: 'justify' }}>
          <b>BioFire Technology Testing:</b> Our BioFire Technology enables rapid identification of pathogens responsible for fever and other infectious conditions, leading to quicker treatment decisions.
          </p>
          <p style={{ textAlign: 'justify' }}>
          <b>Inpatient Fever Care:</b> SHRI Hospital offers comprehensive inpatient care for individuals requiring hospitalization due to fever or fever-related complications.
          </p>
          <p style={{ textAlign: 'justify' }}>
          <b>Outpatient Fever Clinic:</b> Our outpatient fever clinic provides timely consultations, diagnostics, and follow-up care for patients with fever symptoms.
          </p>
          <p style={{ textAlign: 'justify' }}>
          <b>Infectious Disease Management:</b> Our specialists excel in the management of infectious diseases, ensuring accurate diagnosis and appropriate treatment.
          </p>
          <p style={{ textAlign: 'justify' }}>
          <b>Supportive Care:</b> We provide supportive care and personalized treatment plans, aiming to alleviate symptoms and promote recovery.
          </p>
       
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>Contact Us</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}>
        For the best fever care in Guntur with BioFire Technology, trust SHRI Hospital and Dr. Kalyan Chakravarthy Koganti.
          </p>
      </div>

    </>
  )
}

export default Best_Fever_Hospital_in_guntur