import React from 'react'

const SEPSIS_FACTS = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>SEPSIS FACTS</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>



            <div className="container mt-2 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#ff7800" }}>SEPSIS FACTS</span>
                        </h1>
                     

                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Is this patient in sepsis ?</span>
                        </h4 >

                        <p>65 yr old male patient has been referred from a rural hospital with fever, cough and breathlessness of 5 days duration. He is a known diabetic. Patient is conscious but incoherent.</p>
                        <ul>
                            <li>130/70 mm of Hg</li>
                            <li>120/min</li>
                            <li>35/min</li>
                            <li>95% on RA</li>
                            <li>454 mg/dl</li>
                        </ul>
                      
                        <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>Definition</span>
                </h5 >
                <p>Sepsis is a dysregulated inflammatory response to an infection that may lead to life-threatening organ dysfunction. Earlier recognition through the use of screening tools is associated with earlier treatment and decreased mortality</p>

                        {/* <p style={{ textAlign: 'justify' }}>
          Started Center for Infectious Diseases in Guntur with state of the art Micobiology lab supported with automated culture systems, PCR and CB-NAAT technologies.
          </p>
          <p style={{ textAlign: 'justify' }}>
          He is also pioneer in initiating first of its kind integrated health care with remote patient monitoring .Health ATM is his brain child that helps to monitor the patients 24/7 even from home or any other remote location.
          </p> */}
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg mt-2">
                        <img className=" img-thumbnail shadow-lg p-2 mb-5 bg-body rounded" style={{ height: '300px', borderRadius: '10px', width: '300px', boxShadow: 'grey' }} id="ServicesImG" src='SEPSISIMG.jpg' alt=''></img>
                    </div>
                </div>


              
                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>Screening tools</span>
                </h5 >
               <img src='/sepisisTable.jpg' alt=''></img>

                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>Simpler screening tools</span>
                </h5>
                <img src='sepisisSofa.jpg' alt=''></img>
               <h5 className='mt-5' style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>qSOFA (Quick SOFA) Criteria</span>
                </h5>

                <ul>
                    <li>  Respiratory rate ≥22/min</li>
                    <li>  Altered mentation GCS less 13</li>
                    <li>  Systolic blood pressure ≤100 mm Hg</li>
                </ul>
             

             
                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>LqSOFA</span>
                </h5>
                <p>Hence, LqSOFA score is recommended for use, if point of core lactation is available.</p>
                <p>So, the patient mentioned above is likely to be sepsis, as his score is 2 as he has altered mental status and tachypnea.</p>
             
               
            </div>
        </>
    )
}

export default SEPSIS_FACTS