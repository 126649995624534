import React from 'react'

const Friday_Id_Dengue_virus = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row mt-5'>
                    <div className='col-md-7 col-sm-12 '>
                        <p style={{ textAlign: 'justify' }}>Dengue viral illness can still be deadly and needs a close monitoring of certain important haematological and biochemical parameters, and PLATELET COUNT ISN’T AMONG THEM. </p>
                       <p style={{ textAlign: 'justify' }}>Three most important ones are-</p>   
                       <p style={{ textAlign: 'justify' }}>1) Hematocrit(PCV)</p>
                       <p style={{ textAlign: 'justify' }}>2) APTT</p>
                       <p style={{ textAlign: 'justify' }}>3) Liver enzymes with PT/INR, if they are elevated. </p>
                       <p style={{ textAlign: 'justify' }}>We need to monitor them regularly along with platelet count. Thrombocytopenia alone, even if significant is harmless. </p>
                       <p style={{ textAlign: 'justify' }}>TRALI, Transfusion related Acute Lung Injury, is another relatively common complication seen in dengue patients especially with unwarranted platelet transfusions. We needn’t consider platelet transfusions above 20k, unless bleeding manifestations are obvious. </p>
                    </div>
                    <div className='col-md-5 col-sm-12'>
                        <img src='web.whatsapp1.png' style={{ width: '100%' }}></img>
                    </div>
                       <p style={{ textAlign: 'justify' }}>Another very important but rare complication of dengue is HLH(Hemophagocytic Lymphohistiocytosis). Patient’s clinical condition deteriorates very rapidly in HLH and prognosis is very poor if recognition is delayed. It is preferable use H score or HLH diagnostic criteria in case of any suspicion and shift the patient to higher centre without delay, if required. </p>
                       <p style={{ textAlign: 'justify' }}>To summarise, in any infection, detecting organ dysfunction early is very crucial and if delayed can become life threatening.</p>

                </div>
            </div>
        </>
    )
}

export default Friday_Id_Dengue_virus