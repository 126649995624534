import React from 'react'

const Friday_Id_Message_VRE = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row mt-5'>
                    <div className='col-md-7 col-sm-12 mt-4'>
                        <p style={{textAlign:'justify'}}>There appears to be an increasing trend of VRE(Vancomycin Resistant Enterococci) in the recent past, especially in the health care settings.</p>
                        <p style={{textAlign:'justify'}}>VRE bacteremia has been a significant and relatively less considered threat when compared to gram negative sepsis in ICU’s, in India. </p>
                        <p style={{textAlign:'justify'}}>In addition to increased frequency of isolation in urine cultures, blood stream infections have become an alarming scenario. </p>
                        <p style={{textAlign:'justify'}}>Due to the limited treatment options especially in bacteremia, early recognition is the key with regular and standard cultures, along with practicing antibiotic stewardship guidelines.</p>
                     </div>
                    <div className='col-md-5 col-sm-12'>
                        <img src='web.whatsapp1.png' style={{ width: '90%' }}></img>
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default Friday_Id_Message_VRE