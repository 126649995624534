import React from 'react'
import { Link } from 'react-router-dom'


const Fever_Fighting_Food_Your_Guide_to_an_Effective_Fever_Diet = () => {
  return (
    <>
    <div id='Topmargin'></div>
    <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
      <div className="col text-center strip1" style={{ color: 'white' }}>
        <div className="row mt-4" >
          <h1 style={{ textTransform: 'uppercase' }}>Fever Fighting Food Guide to an Effective Fever Diet</h1>
        </div>
        <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
          <li ><a href="/home">Home</a></li>
        </ul>
      </div>
    </div>


   
    <div className="container mt-2 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
      <div className="row">
        <div className="col-lg-7 mb-2">
        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#ff7800" }}>Fever Fighting Food Guide to an Effective Fever Diet</span>
            </h1>
          <p style={{ textAlign: 'justify' }}>
            {/* Graduation from Guntur Medical College and Post Graduation from Manipal Academy of Higher Education. 
                Awarded PG certificate in Infectious diseases from London School of Hygiene and Tropical Medicine, UK and is the 1st ID specialist of our state. */}
            Dealing with a fever can be physically taxing, but providing your body with the right nourishment can significantly aid in recovery. This guide outlines the foods to include in your diet and those to steer clear of during a fever, helping you regain strength and support your immune system.
          </p>

          <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
            <span style={{ color: "rgb(245, 41, 41)" }}>Foods to Include</span>
          </h4 >
          <h5 style={{  fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#ff7800" }}>Chicken Soup: A Time-Tested Remedy</span>
            </h5>

          <p style={{ textAlign: 'justify' }}><b>Description : </b> Chicken soup is hailed as a go-to comfort food for a reason. Its warm broth aids in hydration and helps regulate body temperature. The inclusion of chicken provides a healthy dose of protein, essential for tissue repair and immune function</p>

          {/* <p style={{ textAlign: 'justify' }}>
          Started Center for Infectious Diseases in Guntur with state of the art Micobiology lab supported with automated culture systems, PCR and CB-NAAT technologies.
          </p>
          <p style={{ textAlign: 'justify' }}>
          He is also pioneer in initiating first of its kind integrated health care with remote patient monitoring .Health ATM is his brain child that helps to monitor the patients 24/7 even from home or any other remote location.
          </p> */}
        </div>
        <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg mt-2">
          <img className=" img-thumbnail shadow-lg p-2 mb-5 bg-body rounded" style={{ height: '300px', borderRadius: '10px', width: '300px', boxShadow: 'grey' }} id="ServicesImG" src='Fever-Food-feature-.jpeg' alt=''></img>
        </div>
      </div>
    

          <p style={{ textAlign: 'justify' }}><b>Benefits:</b></p>
          <ul>
            <li>Promotes hydration</li>
            <li>Helps lower body temperature</li>
            <li>Replenishes electrolytes</li>
            <li>Supports immune function with protein content</li>
          </ul>
          <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
            <span style={{ color: "#ff7800" }}>Lean Proteins: Poultry and Fish</span>
          </h5 >
          <p style={{textAlign:'justify'}}><b>Description : </b> Easily digestible lean proteins like chicken and fish are excellent choices during a fever. They deliver essential amino acids necessary for muscle repair without burdening the body with excessive calories.</p>
          <p style={{textAlign:'justify'}}><b>Benefits:</b></p>
          <ul style={{textAlign:'justify'}}>
            <li>Prevents muscle loss</li>
            <li>Supports immune system</li>
            <li>Aids in tissue repair and recovery</li>
          </ul>

          <h5 style={{  fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#ff7800" }}>Fruits and Vegetables: A Nutrient Powerhouse</span>
            </h5>
            <p style={{textAlign:'justify'}}><b>Description : </b> Fruits and vegetables are packed with vitamins, minerals, and antioxidants. Vitamin C from fruits, in particular, boosts immune function, while vegetables contribute vital nutrients like potassium, dietary fiber, and vitamin A.</p>
            <p><b>Benefits:</b></p>
            <ul style={{textAlign:'justify'}}>
                <li>Provides essential vitamins and minerals</li>
                <li>Boosts immune system with antioxidants</li>
                <li>Supports overall health and recovery</li>
            </ul>
            <h5 style={{  fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#ff7800" }}>Whole Grains for Sustained Energy</span>
            </h5>
            <p style={{textAlign:'justify'}}><b>Description : </b> Incorporating whole grains such as brown rice, quinoa, and whole-grain bread into your diet ensures a steady release of energy. They also deliver a range of essential nutrients necessary for recovery.</p>
            <p><b>Benefits:</b></p>
            <ul>
                <li>Sustained energy levels</li>
                <li>Supplies crucial nutrients for recovery</li>
            </ul>

            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
            <span style={{ color: "rgb(245, 41, 41)" }}>Foods to Avoid</span>
          </h4 >
          <h5 style={{  fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#ff7800" }}>Caffeinated and Alcoholic Beverages: Dehydration Culprits</span>
            </h5>
            <p style={{textAlign:'justify'}}><b>Description : </b> Both caffeine and alcohol can lead to dehydration, which is particularly detrimental when the body is already running a fever. Opting for hydrating alternatives is crucial.</p>
            <p><b>Risks:</b></p>
            <ul style={{textAlign:'justify'}}>
                <li>Exacerbates dehydration</li>
                <li>Hinders the body's ability to heal</li>
            </ul>
            <h5 style={{  fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#ff7800" }}>Sugary Foods: The Immunity Buster</span>
            </h5>
            <p style={{textAlign:'justify'}}><b>Description : </b> Foods high in sugar may provide a temporary boost in energy, but they lack the essential nutrients your body needs during a fever. Moreover, excess sugar can lead to inflammation, requiring the immune system to work harder.</p>
            <p><b>Risks:</b></p>
          <ul style={{textAlign:'justify'}}>
            <li>Inadequate nutrition</li>
            <li>Potential inflammation and increased strain on the immune system</li>
          </ul>
         
    </div>

  </>
  )
}

export default Fever_Fighting_Food_Your_Guide_to_an_Effective_Fever_Diet