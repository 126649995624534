import React from 'react'

const How_To_Identify_Scrub_Typhus_And_how_to_Prevent_It  = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Scrub Typhus</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>



            <div className="container mt-2 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#ff7800" }}>Scrub Typhus</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            Scrub typhus is an infectious disease caused by the Orientia tsutsugamushi bacterium, transmitted through the bite of infected chiggers. This blog post aims to provide valuable insights into identifying scrub typhus, understanding its symptoms, and implementing preventive measures to mitigate the risk of infection.
                                 </p>

                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>What is Scrub Typhus?</span>
                        </h4 >
                        <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#ff7800" }}>Definition:</span>
                        </h5>

                        <p style={{ textAlign: 'justify' }}>
                            Scrub typhus is a vector-borne disease prevalent in the Asia-Pacific region. It is transmitted to humans through the bite of infected larval mites, often found in areas with heavy vegetation and in rural settings.          
                                      </p>

         
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg mt-2">
                        <img className=" img-thumbnail shadow-lg p-2 mb-5 bg-body rounded" style={{ height: '200px', borderRadius: '10px', width: '300px', boxShadow: 'grey' }} id="ServicesImG" src='scrub-typhus.png' alt=''></img>
                    </div>
                </div>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Identifying the Vector:</span>
                </h4 >
                

                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#ff7800" }}>Chiggers (Mites):</span>
                        </h5>

              <ul>
                <li>Chiggers are the vectors responsible for transmitting the Orientia tsutsugamushi bacterium.</li>
                <li>They are tiny and often go unnoticed, making preventive measures crucial.</li>
              </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Symptoms of Scrub Typhus</span>
                        </h4 >
                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>Early Symptoms:</span>
                </h5 >
             <ul>
                <li>Fever: Sudden onset of high fever is a common early sign.</li>
                <li>Headache and Muscle Pain: Intense headaches and muscle pain may be experienced.</li>
                <li>Eschar Formation: A dark, scab-like lesion (eschar) may develop at the site of the chigger bite.</li>
             </ul>

                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>Later Symptoms:</span>
                </h5>
             <ul>
                <li>Rash: A rash, often spotted and widespread, may appear.</li>
                <li>Respiratory Distress: Severe cases can lead to respiratory distress and organ failure if left untreated.</li>
             </ul>
              

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>How to Identify Scrub Typhus</span>
                </h4 >
                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>Clinical Evaluation:</span>
                </h5>
               <ul>
                <li>A healthcare professional will conduct a thorough examination, considering symptoms and potential exposure. </li>
                <li>Blood tests may be conducted to confirm the diagnosis.</li>
               </ul>
                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>Eschar Recognition:</span>
                </h5>
                <ul>
                    <li>Identifying the eschar, a dark, painless lesion at the bite site, is crucial for diagnosis.</li>
                    <li>Not all cases present with an eschar, so clinical evaluation is essential.</li>
                </ul>


              <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Preventive Measures</span>
                </h4 >
                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>1. Protective Clothing</span>
                </h5>
               <ul>
                <li>Wear long sleeves, pants, and closed shoes when in areas prone to chiggers.
                    </li>
                <li>Tuck pants into socks to prevent chigger access.</li>
               </ul>

                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>2. Use Insect Repellent:</span>
                </h5>
               <ul>
                <li>Apply insect repellent containing DEET on exposed skin.
                    </li>
                <li>Reapply as directed, especially after sweating or water exposure.</li>
               </ul>
              
                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>3. Environmental Awareness:</span>
                </h5>
               <ul>
                <li>Avoid areas with heavy vegetation, particularly in rural settings.
                    </li>
                <li>Use bed nets treated with insect repellent in endemic regions.</li>
               </ul>

            </div>
        </>
    )
}

export default How_To_Identify_Scrub_Typhus_And_how_to_Prevent_It 