import React from 'react'

const Friday_Id_Messages_WHO_Global_Influenza_Surveillance_And_Response_System_GISRS = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row mt-5'>
                    <div className='col-md-7 col-sm-12'>
                        <p style={{ textAlign: 'justify' }}>WHO Global Influenza Surveillance & Response System(GISRS) consists of 151 national influenza centres across 129 nations worldwide wide. They share surveillance findings, risk assessment and corrective measures including updates on seasonal flu vaccines. </p>
                        <p style={{ textAlign: 'justify' }}>In contrast to many other vaccines, influenza vaccines need to be evaluated regularly as the viruses evolve continuously. </p>
                        <p style={{ textAlign: 'justify' }}>So, every year updated recommendations are made by WHO such that the vaccine is available before the flu season. As it takes 6-8 months for production/approval/distribution, these recommendations are made in February for northern hemisphere and September in southern hemisphere. </p>
                        <p style={{ textAlign: 'justify' }}>For our hemisphere, recently   trivalent vaccine with following subtypes/lineage has been recommended for usage in 2024, flu season, during monsoon/winter starting from July. </p>
                   
                    </div>
                    <div className='col-md-5 col-sm-12'>
                        <img src='web.whatsapp1.png' style={{ width: '100%' }}></img>
                    </div>
                    <p style={{ textAlign: 'justify' }}>Two subtypes of Influenza A(H1N1, H3N2) and one lineage related to B(Victoria)  will be targeted. Quadrivalent vaccine is no longer warranted as another subtype of B(Yamagata) is unlikely to be circulating in the population. </p>
                        <p style={{ textAlign: 'justify' }}>Though flu vaccines aren’t 100% effective, they reduce severity of illness especially in the elderly and risk groups. As there will be regular antigenic drift(gradual genetic change), effectiveness varies between 50-70%. Very rarely there can be an antigenic shift(sudden significant genetic change, creating completely new strain), resulting in pandemic, like H1N1 pandemic, 2009. </p>
                        <p style={{ textAlign: 'justify' }}>So, it’s advisable to take flu vaccines every year, especially those with comorbidities/elderly above 65 yrs. </p>

                </div>
            </div>
        </>
    )
}

export default Friday_Id_Messages_WHO_Global_Influenza_Surveillance_And_Response_System_GISRS