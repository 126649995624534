import React from 'react'

const Friday_Id_Message_Diabetes_related_foot_infections_DFI = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row mt-5'>
                    <div className='col-md-7 col-sm-12'>
                        <p style={{ textAlign: 'justify' }}>Diabetes related foot infections(DFI), need to be graded based on the IWGDF(Intl. Working Group on Diabetic Foot). </p>
                        <p style={{ textAlign: 'justify' }}>1- Uninfected foot ulcer(No local signs/symptoms of infection)</p>
                        <p style={{ textAlign: 'justify' }}>2- Mild(Any 2 of these:</p>
                        <p style={{ textAlign: 'justify' }}>Local swelling/erythema-0.5  to 2 cm/local warmth/local tenderness/purulent discharge)</p>
                        <p style={{ textAlign: 'justify' }}>3- Moderate(Erythema greater than 2cms and/or involving tissue deeper than skin and subcutaneous tissues)</p>
                        <p style={{ textAlign: 'justify' }}>4- Severe(Infection associated with systemic manifestations as per SIRS) Do not treat uninfected foot ulcers(grade 1) with systemic or local antibiotics to promote ulcer healing due to risk of adverse events including collateral damage to the commensal flora and development of drug resistance/interactions. </p>
                        <p style={{ textAlign: 'justify' }}>Consider hospitalisation of patients with grade 4 or grade 3 with relevant comorbidities</p>
                  </div>
                    <div className='col-md-5 col-sm-12'>
                        <img src='web.whatsapp1.png' style={{ width: '100%' }}></img>
                    </div>
                    <p style={{ textAlign: 'justify' }}>Target aerobic gram positive pathogens only(including MRSA) for grade 2(mild infections). Do not target Pseudomonas aeruginosa unless isolated from cultures or clinically suspected strongly. </p>
                        <p style={{ textAlign: 'justify' }}>Administer antibiotics for 1-2 weeks based on severity. Consider 3-4 weeks, if patient has severe peripheral arterial disease and 6 weeks for osteomyelitis, if amputation or bone resection isn’t done. </p>
                        <p style={{ textAlign: 'justify' }}>Consider early surgery(within 24-48 hrs) combined with antibiotics for moderate and severe DFIs.</p>
    
                </div>
            </div>
        </>
    )
}

export default Friday_Id_Message_Diabetes_related_foot_infections_DFI