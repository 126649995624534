import React from 'react'

const Friday_Id_Message_Recent_National_Conference_On_HIV = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row mt-5'>
                    <div className='col-md-7 col-sm-12 '>
                        <p style={{ textAlign: 'justify' }}><b>U = U</b></p>
                        <p style={{ textAlign: 'justify' }}>Undetectable = untransmittable</p>
                        <p style={{ textAlign: 'justify' }}>Just wanted to readdress this after the recent national conference on HIV. It’s almost 7 years since this has been documented well in the HIV multi centric double blinded controlled trials, followed by acceptance and publication in various international societies including WHO and NACO. </p>
                        <p style={{ textAlign: 'justify' }}>It means that if someone’s viral load is undetectable and continues to use antiretroviral therapy properly, he is untransmittable, including sexual transmission and there is no need of post exposure prophylaxis. </p>
                        <p style={{ textAlign: 'justify' }}>Though this a well known fact to many physicians, it hasn’t still penetrated well into the general public and stigma continues. </p>
                        <p style={{ textAlign: 'justify' }}>With the advent of integrase inhibitors, decade ago  and adding them to the first line in the last few years including the national treatment guidelines has brought whole lot of change in the management of HIV, targeting eradication by 2030.</p>
                    </div>
                    <div className='col-md-5 col-sm-12'>
                        <img src='web.whatsapp1.png' style={{ width: '100%' }}></img>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Friday_Id_Message_Recent_National_Conference_On_HIV