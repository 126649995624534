import React from 'react'

const Friday_Id_Message_RSV_Respiratory_Syncytial_Virus = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row mt-5'>
                    <div className='col-md-7 col-sm-12'>
                        <p style={{ textAlign: 'justify' }}>Viral seasonality has been obscured post covid and there is continuous surge of respiratory viral infections most of the time in the last 2-3 years. </p>
                        <p style={{ textAlign: 'justify' }}>Presently from the available data from India and rest of the world suggests that there isn’t any new virus that is threatening but certain important facts to be noted and important one among them is RSV.</p>         
                        <p style={{ textAlign: 'justify' }}>RSV(Respiratory Syncytial Virus) is a common cause of cold and mild respiratory symptoms in winter season across the globe with not much significant morbidity or mortality like COVID-19 or flu in adults, though it can cause significant bronchiolitis in children. </p>
                        <p style={{ textAlign: 'justify' }}>But, recent surge and trends in the last few years and especially this year till now are showing a different picture, with increased incidence of hospitalisation. </p>
                        <p style={{ textAlign: 'justify' }}>Availability of multiplex PCR or utilisation of triple test, Xpert, we are able to detect relatively larger no. of RSV hospitalisations even in adult population. There aren’t any regular antivirals for RSV like flu, except for Ribavarin and immunoglobulins reserved for extremely sick and immunocompromised. </p>
                    </div>
                    <div className='col-md-5 col-sm-12'>
                        <img src='web.whatsapp1.png' style={{ width: '100%' }}></img>
                    </div>
                        <p style={{ textAlign: 'justify' }}>An early diagnosis appears to be an important way in detecting the cause as treatments vary among these viral pneumonias. </p>
                        <p style={{ textAlign: 'justify' }}>RSV vaccine is presently available in US, which is likely to be available in India soon.</p>

                </div>
            </div>
        </>
    )
}

export default Friday_Id_Message_RSV_Respiratory_Syncytial_Virus