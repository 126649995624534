import React from 'react'

const Friday_Id_Message_Unlike_PEP_post_exposure_prophylaxis_for_HIV = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row mt-5'>
                    <div className='col-md-7 col-sm-12 p-2'>
                        <p style={{ textAlign: 'justify' }}>Unlike PEP(post exposure prophylaxis) for HIV, it is little complex for Hepatitis B and C.</p>
                        <p style={{ textAlign: 'justify' }}>Risk of transmission of infection is actually high in Hepatitis B and C when compared to HIV, in case of percutaneous needle stick injury.</p>
                        <p style={{ textAlign: 'justify' }}>In Hepatitis B, risk can as high as 60% if both HBsAg and HBeAg are positive for source patient and upto 30% if only HBsAg is positive. In case of Hepatitis C, it ranges from 1-7%.</p>
                        <p style={{ textAlign: 'justify' }}>PEP in Hepatitis B is majorly dependent upon completing three doses vaccination and evaluating adequate vaccine response(anti HBs Greater Than 10milli IU/ml). In case of unvaccinated/incompletely vaccinated or non-responders(anti HBs less than 10milli IU/ml) HBIG(Hepatitis B immunoglobulin) should be ideally administered within 24 hrs but can be given upto 7 days along with  HBV vaccine, followed by completion of vaccination schedule. </p>
                        <p style={{ textAlign: 'justify' }}>There is no role of PEP in hepatitis C infection. It primarily involves early diagnosis and treatment only, should it occur. </p>                                     

                    </div>
                    <div className='col-md-5 col-sm-12'>
                        <img src='web.whatsapp1.png' style={{ width: '100%' }}></img>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Friday_Id_Message_Unlike_PEP_post_exposure_prophylaxis_for_HIV