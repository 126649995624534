import React from 'react'

const Friday_Id_Message_Candiduria = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row mt-5'>
                    <div className='col-md-7 col-sm-12 p-2'>
                        <p style={{ textAlign: 'justify' }}>Candiduria is a non specific finding that can occur due to contamination of urine sample or colonisation of urinary bladder/in dwelling catheter or can be an invasive upper tract infection/cystitis. </p>
                        <p style={{ textAlign: 'justify' }}>Asymptomatic candiduria doesn’t require antifungals unless the patients are neutropenic or likely to undergo urological procedure. </p>
                        <p style={{ textAlign: 'justify' }}>Cultures must be repeated to confirm the infection associated with imaging and blood cultures, if patients have systemic signs and symptoms. </p>
                        <p style={{ textAlign: 'justify' }}>Persistent candiduria in sick diabetic patients prompts ruling out hydronephrosis, fungal balls or perinephric abscess. </p>
                        <p style={{ textAlign: 'justify' }}>Majority of urinary fungal infections are caused by Candida albicans and are generally sensitive to flucanazole. Amphotericin B is rarely required if resistance to flucanazole is confirmed. Newer azoles or echinocandins aren’t recommended as they fail to achieve adequate urinary concentrations.</p>
                    </div>
                    <div className='col-md-5 col-sm-12'>
                        <img src='web.whatsapp1.png' style={{ width: '100%' }}></img>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Friday_Id_Message_Candiduria