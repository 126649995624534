import React from 'react'

const Typhoid_Fever_Treatment_In_Guntur = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Typhoid Fever</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>



            <div className="container mt-2 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#ff7800" }}>Typhoid Fever</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            {/* Graduation from Guntur Medical College and Post Graduation from Manipal Academy of Higher Education. 
                Awarded PG certificate in Infectious diseases from London School of Hygiene and Tropical Medicine, UK and is the 1st ID specialist of our state. */}
Typhoid fever is a bacterial infection that poses a significant threat to public health, especially in regions with poor sanitation. This blog post aims to provide comprehensive information about Typhoid fever, covering its causes, symptoms, and preventive measures to empower readers with the knowledge needed to safeguard against this infectious disease.
                        </p>

                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>What is Typhoid Fever?</span>
                        </h4 >
                        <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#ff7800" }}>Definition:</span>
                        </h5>

                        <p style={{ textAlign: 'justify' }}>
                        Typhoid fever is caused by the bacterium Salmonella Typhi. It is typically transmitted through contaminated food and water or by coming into direct contact with an infected person.
                        </p>

                        {/* <p style={{ textAlign: 'justify' }}>
          Started Center for Infectious Diseases in Guntur with state of the art Micobiology lab supported with automated culture systems, PCR and CB-NAAT technologies.
          </p>
          <p style={{ textAlign: 'justify' }}>
          He is also pioneer in initiating first of its kind integrated health care with remote patient monitoring .Health ATM is his brain child that helps to monitor the patients 24/7 even from home or any other remote location.
          </p> */}
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg mt-2">
                        <img className=" img-thumbnail shadow-lg p-2 mb-5 bg-body rounded" style={{ height: '300px', borderRadius: '10px', width: '300px', boxShadow: 'grey' }} id="ServicesImG" src='typhoid-fever.png' alt=''></img>
                    </div>
                </div>
                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#ff7800" }}>Global Impact:</span>
                        </h5>

                <p style={{ textAlign: 'justify' }}>
                Typhoid fever remains a major health concern in many developing countries where sanitation infrastructure is inadequate. The World Health Organization estimates millions of cases worldwide each year.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Symptoms of Typhoid Fever</span>
                        </h4 >
                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>Early Symptoms:</span>
                </h5 >
              <ul>
                <li>Fever and Headache: Persistent high fever and headache are early signs.</li>
                <li>Weakness and Fatigue: Generalized weakness and fatigue may be experienced.</li>
                <li>Stomach Pain and Constipation: Abdominal discomfort and constipation are common.</li>
              </ul>

                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>Later Symptoms:</span>
                </h5>
              <ul>
                <li>Rose Spots: Characteristic rose-colored spots may appear on the trunk.</li>
                <li>Worsening Symptoms: Without treatment, symptoms can worsen, leading to severe complications.</li>
              </ul>
              

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Causes and Transmission</span>
                </h4 >
                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>Bacterial Source:</span>
                </h5>
                <ul>
                    <li>Typhoid fever is caused by the Salmonella Typhi bacterium.</li>
                    <li>Contaminated food and water are primary sources of infection.</li>
                </ul>
                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>Transmission:</span>
                </h5>
              <ul>
                <li>Fecal-oral transmission occurs through the ingestion of contaminated food or water.</li>
                <li>Close contact with an infected person can also lead to transmission.</li>
              </ul>


              <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Prevention Strategies</span>
                </h4 >
                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>Safe Hygiene Practices:</span>
                </h5>
                <ul>
                    <li>Ensure access to clean and safe drinking water.</li>
                    <li>Practice proper hand hygiene, especially before handling food.</li>
                </ul>

                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>Vaccination:</span>
                </h5>
                <ul>
                    <li>Typhoid vaccines are available and recommended for individuals traveling to endemic areas.</li>
                    <li>Consult with healthcare professionals for vaccination advice.</li>
                </ul>

              
                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>Safe Food Handling:</span>
                </h5>
                <ul>
                    <li>Consume only properly cooked food.</li>
                    <li>Avoid raw or undercooked eggs and meats.</li>
                </ul>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}> Treatment Options</span>
                </h4 >
                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>Antibiotics:</span>
                </h5>
                <ul>
                    <li>Typhoid fever is treatable with antibiotics.</li>
                    <li>Prompt diagnosis and appropriate antibiotic therapy are crucial for a successful recovery.</li>
                </ul>

               
                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#ff7800" }}>Supportive Care:</span>
                </h5>

                <ul>
                    <li>Adequate hydration and nutrition are essential during the recovery period.</li>
                    <li>Hospitalization may be necessary for severe cases.</li>
                </ul>
             

            </div>
        </>
    )
}

export default Typhoid_Fever_Treatment_In_Guntur