
import './App.css';
import Header from './Header';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Home from './Home';
import 'bootstrap/dist/css/bootstrap.min.css';

import Contact from './Contact';

import ScrollToTop from "react-scroll-to-top";
import Footer from './Footer';
import Dr_Kalyan_Chakravarthy_Koganti from './Dr_Kalyan_Chakravarthy_Koganti';
import Best_Fever_Hospital_in_guntur from './Best_Fever_Hospital_in_guntur';
import Sepsis_and_Infectious_Diseases from './Sepsis_and_Infectious_Diseases';

import Lucidly_Integrated_Vital_Care_In_Emergency_in_Guntur from './lucidly_Integrated_Vital_Care_In_Emergency_in_Guntur';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import BEST_CENTRE_FOR_INFECTIOUS_DISEASES from './BEST_CENTRE_FOR_INFECTIOUS_DISEASES';
import Smart_icu_launched_at_phc_in_Chilakaluripeta_andhra_pradesh from './smart_icu_launched_at_phc_in_Chilakaluripeta_andhra_pradesh';
import Blogs from './Blogs';
import Fever_Fighting_Food_Your_Guide_to_an_Effective_Fever_Diet from './Fever_Fighting_Food_Your_Guide_to_an_Effective_Fever_Diet';
import Why_colds_and_flu_viruses_are_more_common_in_winter from './Why_colds_and_flu_viruses_are_more_common_in_winter';
import Best_General_Medicine_Hospitals_In_Guntur from './Best_General_Medicine_Hospitals_In_Guntur';
import SEPSIS_FACTS from './SEPSIS_FACTS';
import Diabetic_diet from './Diabetic_diet';
import Dengue_Fever_Treatment_in_Guntur from './Dengue_Fever_Treatment_in_Guntur';
import Typhoid_Fever_Treatment_In_Guntur from './Typhoid_Fever_Treatment_In_Guntur';
import How_To_Identify_Scrub_Typhus_And_how_to_Prevent_It from './How_To_Identify_Scrub_Typhus_And_how_to_Prevent_It ';
import Friday_Id from './Friday_Id_Day';
import Friday_Id_Message_Sepsis_Fact from './Friday_Id_Message_Sepsis_Fact';
import Friday_Id_Message_Procalcitonin_is_a_biomarker_for_systemic_bacterial_infections from './Friday_Id_Message_Procalcitonin_is_a_biomarker_for_systemic_bacterial_infections';
import Friday_Id_Message_Patients_with_sepsis_induced_hypoperfusion_or_shock from './Friday_Id_Message_Patients_with_sepsis_induced_hypoperfusion_or_shock';
import Friday_Id_Message_Patients_with_sepsis_induced_shock from './Friday_Id_Message_Patients_with_sepsis_induced_shock';
import Friday_Id_Message_Patients_with_sepsis_or_septic_shock from './Friday_Id_Message_Patients_with_sepsis_or_septic_shock';
import Friday_Id_In_patients_with_probable_sepsis from './Friday_Id_In_patients_with_probable_sepsis';
import Friday_Id_Message_In_view_of_rising_multi_drug_resistance_MDR from './Friday_Id_Message_In_view_of_rising_multi_drug_resistance_MDR';
import Friday_Id_Message_Anamnestic_response from './Friday_Id_Message_Anamnestic_response';
import Friday_Id_Message_Lucidly_Integrated_Vitalcare_in_Emergency_LIVE from './Friday_Id_Message_Lucidly_Integrated_Vitalcare_in_Emergency_LIVE';
import Friday_Id_Dengue_virus from './Friday_Id_Dengue_virus';
import Friday_Id_Orientia_tsutsugamushi from './Friday_Id_Orientia_tsutsugamushi';
import Friday_Id_Message_Candiduria from './Friday_Id_Message_Candiduria';
import Friday_Id_Message_Diabetes_related_foot_infections_DFI from './Friday_Id_Message_Diabetes_related_foot_infections_DFI';
import Friday_Id_Messages_WHO_Global_Influenza_Surveillance_And_Response_System_GISRS from './Friday_Id_Messages_WHO_Global_Influenza_Surveillance_And_Response_System_GISRS';
import Friday_Id_Message_Rabies_immunoglobulin_RIG from './Friday_Id_Message_Rabies_immunoglobulin_RIG';
import Friday_Id_Message_Microbiome from './Friday_Id_Message_Microbiome';
import Friday_Id_Message_Unlike_PEP_post_exposure_prophylaxis_for_HIV from './Friday_Id_Message_Unlike_PEP_post_exposure_prophylaxis_for_HIV';
import Friday_Id_Message_world_HIV_day from './Friday_Id_Message_world_HIV_day';
import Friday_Id_Shortly_on_the_present_scenario_on_COVID_19 from './Friday_Id_Shortly_on_the_present_scenario_on_COVID_19';
import Friday_Id_Message_RSV_Respiratory_Syncytial_Virus from './Friday_Id_Message_RSV_Respiratory_Syncytial_Virus';
import Images from './Images';
import Videos from './Videos';
import Insurance from './Insurance';
import Friday_Id_Message__MIC_Minimum_Inhibitory_Concentration from './Friday_Id_Message__MIC_Minimum_Inhibitory_Concentration';
import Any_Time_Monitoring from './Any_Time_Monitoring';
import Friday_Id_Message_Resistance_of_Antibiotics from './Friday_Id_Message_Resistance_of_Antibiotics';
import Friday_Id_Message_VRE from './Friday_Id_Message_VRE';
import Presentations_and_Publications from './Presentations_and_Publications';
import Friday_Id_Message_Recent_National_Conference_On_HIV from './Friday_Id_Message_Recent_National_Conference_On_HIV';
import Patients_in_remote_areas_being_treated_through_smart_ICU_programme_in_Guntur from './Patients_in_remote_areas_being_treated_through_smart_ICU_programme_in_Guntur';





function App() {
  return (
    <>

      <BrowserRouter>
        <Header />

        <HelmetProvider>
          <Helmet>

            <meta charSet="utf-8" />
            <title>Best General Physician in Guntur </title>
            <meta property="og:title" content="Best General Physician in Guntur" />
            <meta property="og:site_name" content="Best General Physician in Guntur " />
            <meta property="og:description"
              content="Dr. Kalyan Chakravarthy Koganti, a visionary and dedicated medical professional, is the founder of CID Centre For Infectious Diseases." />

            {/* <meta property="og:image" content="http://helphospital.in/Help%20LOGO%20(1).png" /> */}
          </Helmet>
        </HelmetProvider>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path='/Dr_Kalyan_Chakravarthy_Koganti' element={<Dr_Kalyan_Chakravarthy_Koganti />} />
          <Route path='/Best_Fever_Hospital_in_guntur' element={<Best_Fever_Hospital_in_guntur />} />
          <Route path='/Sepsis_and_Infectious_Diseases' element={<Sepsis_and_Infectious_Diseases />} />
          <Route path='/BEST_CENTRE_FOR_INFECTIOUS_DISEASES' element={<BEST_CENTRE_FOR_INFECTIOUS_DISEASES />} />
          <Route path='/Lucidly_Integrated_Vital_Care_In_Emergency_in_Guntur' element={<Lucidly_Integrated_Vital_Care_In_Emergency_in_Guntur />} />
          <Route path="/Smart_icu_launched_at_phc_in_Chilakaluripeta_andhra_pradesh" element={<Smart_icu_launched_at_phc_in_Chilakaluripeta_andhra_pradesh />} />
          <Route path="/Blogs" element={<Blogs />} />
          <Route path="/Fever_Fighting_Food_Your_Guide_to_an_Effective_Fever_Diet" element={<Fever_Fighting_Food_Your_Guide_to_an_Effective_Fever_Diet />} />
          <Route path="/Why_colds_and_flu_viruses_are_more_common_in_winter" element={<Why_colds_and_flu_viruses_are_more_common_in_winter />} />
          <Route path='/Best_General_Medicine_Hospitals_In_Guntur' element={<Best_General_Medicine_Hospitals_In_Guntur />} />
          <Route path='/SEPSIS_FACTS' element={<SEPSIS_FACTS />} />
          <Route path='/Diabetic_diet' element={<Diabetic_diet />} />
          <Route path='/Dengue_Fever_Treatment_in_Guntur' element={<Dengue_Fever_Treatment_in_Guntur />} />
          <Route path='/Typhoid_Fever_Treatment_In_Guntur' element={<Typhoid_Fever_Treatment_In_Guntur />} />
          <Route path='/How_To_Identify_Scrub_Typhus_And_how_to_Prevent_It' element={<How_To_Identify_Scrub_Typhus_And_how_to_Prevent_It />} />
          <Route path="/Friday_Id_Day" element={<Friday_Id />} />
          <Route path="/Friday_Id_Message_Sepsis_Fact" element={<Friday_Id_Message_Sepsis_Fact />} />
          <Route path="/Friday_Id_Message_Procalcitonin_is_a_biomarker_for_systemic_bacterial_infections" element={<Friday_Id_Message_Procalcitonin_is_a_biomarker_for_systemic_bacterial_infections />} />
          <Route path="/Friday_Id_Message_Patients_with_sepsis_induced_hypoperfusion_or_shock" element={<Friday_Id_Message_Patients_with_sepsis_induced_hypoperfusion_or_shock />} />
          <Route path="/Friday_Id_Message_Patients_with_sepsis_induced_shock" element={<Friday_Id_Message_Patients_with_sepsis_induced_shock />} />
          <Route path="/Friday_Id_Message_Patients_with_sepsis_or_septic_shock" element={<Friday_Id_Message_Patients_with_sepsis_or_septic_shock />} />
          <Route path="/Friday_Id_In_patients_with_probable_sepsis" element={<Friday_Id_In_patients_with_probable_sepsis />} />
          <Route path="/Friday_Id_Message_In_view_of_rising_multi_drug_resistance_MDR" element={<Friday_Id_Message_In_view_of_rising_multi_drug_resistance_MDR />} />
          <Route path="/Friday_Id_Message_Anamnestic_response" element={<Friday_Id_Message_Anamnestic_response />} />
          <Route path="/Friday_Id_Message_Lucidly_Integrated_Vitalcare_in_Emergency_LIVE" element={<Friday_Id_Message_Lucidly_Integrated_Vitalcare_in_Emergency_LIVE />} />
          <Route path="/Friday_Id_Dengue_virus" element={<Friday_Id_Dengue_virus />} />
          <Route path="/Friday_Id_Orientia_tsutsugamushi" element={<Friday_Id_Orientia_tsutsugamushi />} />
          <Route path="/Friday_Id_Message_Candiduria" element={<Friday_Id_Message_Candiduria />} />
          <Route path="/Friday_Id_Message_Diabetes_related_foot_infections_DFI" element={<Friday_Id_Message_Diabetes_related_foot_infections_DFI />} />
          <Route path="/Friday_Id_Messages_WHO_Global_Influenza_Surveillance_And_Response_System_GISRS" element={<Friday_Id_Messages_WHO_Global_Influenza_Surveillance_And_Response_System_GISRS />} />
          <Route path="/Friday_Id_Message_Rabies_immunoglobulin_RIG" element={<Friday_Id_Message_Rabies_immunoglobulin_RIG />} />
          <Route path="/Friday_Id_Message_Microbiome" element={<Friday_Id_Message_Microbiome />} />
          <Route path="/Friday_Id_Message_Unlike_PEP_post_exposure_prophylaxis_for_HIV" element={<Friday_Id_Message_Unlike_PEP_post_exposure_prophylaxis_for_HIV />} />
          <Route path='/Friday_Id_Message_world_HIV_day' element={<Friday_Id_Message_world_HIV_day />} />
          <Route path='/Friday_Id_Shortly_on_the_present_scenario_on_COVID_19' element={<Friday_Id_Shortly_on_the_present_scenario_on_COVID_19 />} />
          <Route path='/Friday_Id_Message_RSV_Respiratory_Syncytial_Virus' element={<Friday_Id_Message_RSV_Respiratory_Syncytial_Virus />} />
          <Route path="/Images" element={<Images />} />
          <Route path="/Videos" element={<Videos />} />
          <Route path='/Insurance' element={<Insurance />} />
          <Route path='/Friday_Id_Message__MIC_Minimum_Inhibitory_Concentration' element={<Friday_Id_Message__MIC_Minimum_Inhibitory_Concentration />} />
          <Route path='/Any_Time_Monitoring' element={<Any_Time_Monitoring/>}/>
          <Route path='/Friday_Id_Message_Resistance_of_Antibiotics' element={<Friday_Id_Message_Resistance_of_Antibiotics/>}/>
          <Route path='/Friday_Id_Message_VRE' element={<Friday_Id_Message_VRE/>}/>
          <Route path='/Presentations_and_Publications' element={<Presentations_and_Publications/>}/>
          <Route path='/Friday_Id_Message_Recent_National_Conference_On_HIV' element={<Friday_Id_Message_Recent_National_Conference_On_HIV/>}/>
          <Route path='/Patients_in_remote_areas_being_treated_through_smart_ICU_programme_in_Guntur' element={<Patients_in_remote_areas_being_treated_through_smart_ICU_programme_in_Guntur/>}/>
        </Routes>
        {/* 
        <div className='right-navigation'>
          <Link to='Insurance' className='insurance_float'><img className="whatsapp_float" src='insu6.webp' alt=''></img>Insurance</Link>
        </div> */}
        <div className="GoTop"  >
          <ScrollToTop smooth style={{ backgroundColor: 'rgb(245, 41, 41)', bottom: '10px', width: '35px', height: '35px' }} />
        </div>
        <div className='sbuttons' >
        <Link className='sbutton youtube' to='https://www.youtube.com/channel/UCekR_-Ad9pYEbsumIDBFJsA' title='Youtube'><i className="bi bi-youtube"></i></Link><br></br>
          <Link className='sbutton facebook' to='https://www.facebook.com/drkalyanchakravarthykogantihelphospital/' title='Facebook'><i className="bi bi-facebook"></i> </Link><br></br>
          <Link className='sbutton linkedin' to='https://www.linkedin.com/in/kalyan-koganti-7933a723a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app' title='Linkedin'><i className='bi bi-linkedin'></i></Link><br></br>

          <Link className='sbutton instagram' to='https://www.instagram.com/dr.kalyanchakravarthykoganti/' title='Instagram' ><i className="bi bi-instagram"></i></Link><br></br>
        </div>

        {/* <div class="sbuttons">

          <a href="#" target="_blank" class="sbutton whatsapp" tooltip="WhatsApp"><i class="fab fa-whatsapp"></i></a>

          <a href="#" target="_blank" class="sbutton fb" tooltip="Facebook"><i class="fab fa-facebook-f"></i></a>

          <a href="#" target="_blank" class="sbutton twitt" tooltip="Twitter"><i class="fab fa-twitter"></i></a>

          <a href="#" target="_blank" class="sbutton pinteres" tooltip="Pinterest"><i class="fab fa-pinterest-p"></i></a>

          <a target="_blank" class="sbutton mainsbutton" tooltip="Share"><i class="fas fa-share-alt"></i></a>

        </div> */}


        <Footer />
      </BrowserRouter>




    </>
  );
}

export default App;
