import React from 'react'

const Friday_Id_Message_Patients_with_sepsis_induced_hypoperfusion_or_shock = () => {
  return (
    <>
      <div id='Topmargin'></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
          </ul>
        </div>
      </div>

      <div className='container'>
        <div className='row mt-5'>
          <div className='col-md-7 col-sm-12'>
            <p style={{ textAlign: 'justify' }}>Patients with sepsis induced hypoperfusion or shock, we need to give atleast 30ml/kg of IV crystalloid fluids within the first 3 hrs of resuscitation. </p>
            <p style={{ textAlign: 'justify' }}>Resuscitation further can be guided by serum lactate levels or cardiac output/stroke volume assessments. In resource limited settings, pulse pressure variation can be quite useful.Greater than 15% increase in pulse pressure indicates that the patient is fluid responsive utilising passive leg raise test for 60-90 seconds. </p>
            <p style={{ textAlign: 'justify' }}>Balanced crystalloid solutions like Plasma-Lyte is preferred over normal saline in order to have fewer adverse events secondary to hyperchloremic metabolic acidosis and there by reduce the need of vasopressors.</p>
           
                    </div>
          <div className='col-md-5 col-sm-12'>
            <img src='web.whatsapp1.png' style={{ width: '100%' }}></img>
          </div>
        </div>
      </div>
    </>
  )
}

export default Friday_Id_Message_Patients_with_sepsis_induced_hypoperfusion_or_shock