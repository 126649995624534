import React from 'react'

const Friday_Id_Shortly_on_the_present_scenario_on_COVID_19 = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row mt-5'>
                    <div className='col-md-7 col-sm-12 '>
                        <p style={{ textAlign: 'justify' }}>Shortly on the present scenario on COVID-19.</p>
                        <p style={{ textAlign: 'justify' }}>JN. 1 sub variant of Omicron, descendant from BA.2.86 with probable mutation in the spike protein, is spreading fast globally. </p>
                        <p style={{ textAlign: 'justify' }}>It has been declared as ‘variant of interest’ by WHO as of now, awaiting more data if at all to be considered as ‘variant of concern’.  Initial reports suggest that there isn’t significant mortality and vaccinations done appear to be fairly protective. </p>
                        <p style={{ textAlign: 'justify' }}>Wearing a face mask in crowded places, hand wash and other regular precautions to prevent infection and severe disease  is suggested. </p>
                        <p style={{ textAlign: 'justify' }}>Elderly with comorbidities need to be monitored closely if symptoms arise. </p>
                        <p style={{ textAlign: 'justify' }}>Serial CRP’s and NLR(Neutrophil/Lymphocyte ratio) need to be monitored cautiously.</p>
                        <p style={{ textAlign: 'justify' }}>Paxlovid is right now available in the market for high risk category with confirmed infection.</p>          

                    </div>
                    <div className='col-md-5 col-sm-12'>
                        <img src='web.whatsapp1.png' style={{ width: '100%' }}></img>
                    </div>
                  
                  
                </div>
            </div>
        </>
    )
}

export default Friday_Id_Shortly_on_the_present_scenario_on_COVID_19