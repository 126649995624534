import React from 'react'

const Friday_Id_Message_Patients_with_sepsis_or_septic_shock = () => {
    return (
        <>
          <div id='Topmargin'></div>
          <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
            <div className="col text-center strip1" style={{ color: 'white' }}>
              <div className="row mt-4" >
                <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
              </div>
              <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                <li ><a href="/home">Home</a></li>
              </ul>
            </div>
          </div>
    
          <div className='container'>
            <div className='row mt-5'>
              <div className='col-md-7 col-sm-12 '>
                <p style={{ textAlign: 'justify' }}>Patients with sepsis or septic shock, administer antibiotics immediately, ideally within one hour of recognition. </p>
                <p style={{ textAlign: 'justify' }}>Patients with  HIGH RISK of MDR(multi drug resistant) infections, two antimicrobials with gram negative coverage have to be used for empiric treatment. </p>       
                <p style={{ textAlign: 'justify' }}>More importantly, dosing and delivery of antibiotics must be based on PK/PD(pharmacokinetics/pharmacodynamics) principles and specific drug properties. </p>   
                <p style={{ textAlign: 'justify' }}>For eg; Aminoglycosides- extended interval dosing, Betalactams- prolonged infusion. </p>   
                <p style={{ textAlign: 'justify' }}>Administer loading doses is essential to avoid delays in achieving effective concentrations followed by adjustments based on renal clearance and other PK/PD parameters. </p>           
                <p style={{ textAlign: 'justify' }}>Daily assessment for de-escalation of antimicrobials is suggestive over fixed durations of therapy without reassessment .</p>
              </div>
              <div className='col-md-5 col-sm-12'>
                <img src='web.whatsapp1.png' style={{ width: '100%' }}></img>
              </div>
            </div>
          </div>
        </>
      )
}

export default Friday_Id_Message_Patients_with_sepsis_or_septic_shock