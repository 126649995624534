import React from 'react'

const Friday_Id_Message_Anamnestic_response = () => {
    return (
        <>
            <div id='Topmargin'></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Friday Id Day</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row mt-5'>
                    <div className='col-md-7 col-sm-12'>
                        <p style={{ textAlign: 'justify' }}>Anamnestic response, a sudden accelerated rise in antibodies due to a secondary infection and cross reactivity must be kept in mind while reading rapid antibody tests.  </p>
                        <p style={{ textAlign: 'justify' }}>In our area, in the last few weeks there is surge in tropical infections along with Influenza A. </p>
                        <p style={{ textAlign: 'justify' }}>As clinical presentation can be similar in all these infections, rapid tests appear to be helpful but we must read them very carefully. </p>
                        <p style={{ textAlign: 'justify' }}>We all know that presence of IgM antibodies suggest recent infection and IgG, past infection. But, these antibodies can start rising due to other infections or inadvertently due to anamnestic response as well. So, only rising titres using ELISA is mandatory to confirm the infection. </p>
                        <p style={{ textAlign: 'justify' }}>Rapid diagnostic tests(RDT’s) must be used for screening purposes and further confirmatory tests are very important. </p>
                       
                    </div>
                    <div className='col-md-5 col-sm-12 mt-3'>
                        <img src='web.whatsapp1.png' style={{ width: '100%' }}></img>
                    </div>
                    <p style={{ textAlign: 'justify' }}>There can also be a coinfection, hence patient needs to be assessed and monitored on a regular basis. </p>
                        <p style={{ textAlign: 'justify' }}>Adequate hydration appears to be the most important early single step in the present situation and then evaluating further if symptoms persist. </p>

                </div>
                
            </div>
        </>
    )
}

export default Friday_Id_Message_Anamnestic_response